import React, { useState } from 'react'   
import { Link, useNavigate } from 'react-router-dom'
import axioInstance from '../axiosInstance'
 
export default function AdminDashboardHeaderNew() {

    let navigate = useNavigate();

    const adminlogOut = () => {

        axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'logout', admin_id: sessionStorage.getItem('admin_id') }).then(function (response, data) {

            sessionStorage.clear();

                // Clear "Remember Me" data
            localStorage.removeItem('remember_email');
            localStorage.removeItem('remember_password');
            localStorage.removeItem('remember_timestamp');

            navigate("/ulogin");

        }); 
    }
    
 // eslint-disable-next-line
const [role, setRole] = useState(sessionStorage.getItem('admin_role') === '0' ? 'Admin' : 'Planner');
 // eslint-disable-next-line
const [shortName, setShortName] = useState(sessionStorage.getItem('admin_name'));
    return (
        <> 
           <div className="header header-one"> 
                <Link to="/admin-dashboard"  className="d-inline-flex d-sm-inline-flex align-items-center d-md-inline-flex d-lg-none align-items-center device-logo">
                    <img src="images/logo-large.png" className="img-fluid logo2" alt="Logo" />
                </Link>
                <div className="main-logo d-inline float-start d-lg-flex align-items-center d-none d-sm-none d-md-none">
                    <div className="logo-white">
                        <Link to="/admin-dashboard">
                            <img src="images/logo-large.png" className="img-fluid logo-blue" alt="Logo" />
                        </Link>
                        <Link to="/admin-dashboard">
                            <img src="images/finnoNewLogo.jpg" className="img-fluid logo-small" alt="Logo" />
                        </Link>
                    </div>
                    <div className="logo-color">
                        <Link to="/admin-dashboard">
                            <img src="images/logo.png" className="img-fluid logo-blue" alt="Logo" />
                        </Link>
                        <Link to="/admin-dashboard">
                            <img src="images/finnoNewLogo.jpg" className="img-fluid logo-small" alt="Logo" />
                        </Link>
                    </div>
                </div>


                <Link to={window.location.href} id="toggle_btn">
                    <span className="fas fa-bars toggle-bars_">
                    <span className="bar-icons"></span>
                    <span className="bar-icons"></span>
                    <span className="bar-icons"></span>
                    <span className="bar-icons"></span>
                    </span>
                </Link>
                <div className="top-nav-search">
                     <form>
                        <input type="text" className="form-control" placeholder="Search here" />
                        <button className="btn" type="submit"><img src="assets/img/icons/search.svg" alt="img" /></button>
                    </form>
                </div>

                <Link to={window.location.href} className="mobile_btn" id="mobile_btn">
                    <i className="fas fa-bars"></i>
                </Link>

                <ul className="nav nav-tabs user-menu">
                    <li className="nav-item  has-arrow dropdown-heads ">
                    <Link  to={window.location.href} className="win-maximize">
                        <i className="fe fe-maximize"></i>
                    </Link>
                    </li>

                    <li className="nav-item dropdown">
                    <Link
                        to={window.location.href}
                        className="user-link  nav-link"
                        data-bs-toggle="dropdown"
                    >
                        <span className="user-img">
                         <img src="assets/img/profiles/avatar.png" alt="img" className="profilesidebar" />
                        <span className="animate-circle"></span>
                        </span>
                        <span className="user-content">
                        <span className="user-details">{role}</span>
                        <span className="user-name">{shortName}</span>
                        </span>
                    </Link>
                    <div className="dropdown-menu menu-drop-user">
                        <div className="profilemenu">
                      {/*   <div className="subscription-menu">
                            <ul>
                            <li>
                                <a className="dropdown-item" to="/">
                                Profile
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" to="/">
                                Settings
                                </a>
                            </li>
                            </ul>
                        </div> */}
                        <div className="subscription-logout">
                            <ul>
                            <li>
                                <Link onClick={()=>{ adminlogOut() } } className="dropdown-item" href="#">
                                    Logout
                                </Link>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>  
        </>
    )
}
import React, {  useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { Link, useNavigate } from 'react-router-dom' 
import AddTestProfileSidebar from '../components/AddTestProfileSidebar'; 
import { AvatarImage, Encryption } from '../components/global';
import NewTheme from '../components/admin-dashboard/NewTheme';
import SidebarPopupComponent from '../components/admin-dashboard/SidebarPopupComponent';
import TableContainer from '../components/admin-dashboard/TableContainer'; 
export default function ReportProfileTimelineNew() {
  let navigate = useNavigate();

// eslint-disable-next-line
const [loggedInUser, setLoggedInUser] = useState( sessionStorage.getItem("admin_id") ? sessionStorage.getItem("admin_id") : null )

  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line
  const [selectedPlanner, setSelectedPlanner] = useState('');
  
  // eslint-disable-next-line
  const [selectedRelManager, setSelectedRelManager] = useState('');
  
  // State for the input values
  const [filters, setFilters] = useState({
    plan_status: '',
    plan_mapped: '',
    plan_date: '',
    date_filter: '',
    filterDate: '',
    plan_anniversary_years: '',
    plan_anniversary: ''
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value
    }));
  };

  const handleFilter = () => {

    setLoading(true);

    const queryParams = new URLSearchParams(filters).toString();
    const url = `plan-profiles-timeline?${queryParams}`;
    window.location.href = url;

  }

  const resetFilter = () => { 
    window.location.href = 'plan-profiles-timeline'; 
  }

  const loginUser = (id, emailAddress) => {
    axioInstance.get(`admin/user-login/` + id).then(
      (response, data) => {
        sessionStorage.setItem('token', response.data.token);
        const pdata = { "email": Encryption(emailAddress) };
        axioInstance.post(`profile/view`, pdata).then(function (response) {
          sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));
          sessionStorage.setItem('full_name', response.data.first_name + '\'s');
          sessionStorage.setItem('profile_details_id', Encryption(response.data.profile_details_id));
          if (response.data.step > 3) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/basic-profile";
          }
        });
      }
    );
  }

  const [planStatus, setPlanStatus] = useState('');
  // eslint-disable-next-line
  const [planMapped, setPlanMapped] = useState('');
  // eslint-disable-next-line
  const [planDate, setPlanDate] = useState('');
  // eslint-disable-next-line
  const [dateFilter, setDateFilter] = useState('');
  // eslint-disable-next-line
  const [filterDate, setFilterDate] = useState('');
  // eslint-disable-next-line
  const [planAnniversaryYears, setPlanAnniversaryYears] = useState('');
  // eslint-disable-next-line
  const [planAnniversary, setPlanAnniversary] = useState('');
  

  useEffect(() => {
    //debugger; // Execution will pause here if DevTools is open
    const loggedInUser2 = localStorage.getItem("admin_id");
   console.log("loggedInUser2:::", loggedInUser2);
    if (loggedInUser2 === null ) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const plan_status = params.get('plan_status') ? params.get('plan_status') : '';
      const plan_mapped = params.get('plan_mapped') ? params.get('plan_mapped') : '';
      const plan_date = params.get('plan_date') ? params.get('plan_date') : '';
      const date_filter = params.get('date_filter') ? params.get('date_filter') : '';
      const filterDate = params.get('filterDate') ? params.get('filterDate') : '';
      const plan_anniversary_years = params.get('plan_anniversary_years') ? params.get('plan_anniversary_years') : '';
      const plan_anniversary = params.get('plan_anniversary') ? params.get('plan_anniversary') : '';

      setFilters({
        plan_status: plan_status,
        plan_mapped: plan_mapped,
        plan_date: plan_date,
        date_filter: date_filter,
        filterDate: filterDate,
        plan_anniversary_years: plan_anniversary_years,
        plan_anniversary: plan_anniversary
      });
      
      if (plan_status) {
        setPlanStatus(plan_status);
      }

      if (plan_mapped) {
        setPlanMapped(plan_mapped);
      }

      if (plan_date) {
        setPlanDate(plan_date);
      }

      if (date_filter) {
        setDateFilter(date_filter);
      }

      if (filterDate) {
        setFilterDate(filterDate);
      }

      if (plan_anniversary_years) {
        setPlanAnniversaryYears(plan_anniversary_years);
      }

      if (plan_anniversary) {
        setPlanAnniversary(plan_anniversary);
      }

      var PostData = '';

      PostData = {
        plan_status: plan_status,
        plan_mapped: plan_mapped,
        plan_date: plan_date,
        date_filter: date_filter,
        filterDate: filterDate,
        plan_anniversary_years: plan_anniversary_years,
        plan_anniversary: plan_anniversary
      }

      const filteredPostData = Object.fromEntries(
        Object.entries(PostData).filter(([_, value]) => value !== '')
      );

      axioInstance.post(`admin/plan-profiles-timeline`, filteredPostData).then(
        (response, data) => {
          //console.log(response.data);
          setData(response.data.data);
          setTableRowsData(response.data.data);
          setLoading(false);
        }
      );

      axioInstance.get(`admin/get-admins`).then(
        (response, data) => {
          //console.log(response.data);
          setRelManager(response.data);
        }
      );
 
    }
  }, [navigate]);




  const uniquePlanners = [...new Set(data.map(item => item.admin_name))].filter(planner => planner).sort((a, b) => a.localeCompare(b)); 

  const uniqueRelManager = [...new Set(data.map(item => item.primary_rel_manager))].filter(manager => manager).sort((a, b) => a.localeCompare(b)); ;
/* 
  useEffect(() => {
    let filteredData = data;

    if (selectedPlanner !== 'ALL') {
      filteredData = filteredData.filter(item => item.admin_name === selectedPlanner);
    }

    if (selectedRelManager !== 'ALL') {
      filteredData = filteredData.filter(item => item.primary_rel_manager === selectedRelManager);
    }

    setTableRowsData(filteredData);
  }, [selectedPlanner, selectedRelManager, data]);
 */
/* 
  const columns = [
    {
      name: 'ID',
      selector: row => row.profile_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Primary Planner',
      selector: row => row.primary_rel_manager,
      sortable: true,
    },
    {
      name: 'Plan Created',
      selector: row => row.created_date,
      sortable: false,
    },
    {
      name: 'Step Completed',
      selector: row => row.steps_completed_date,
      sortable: false,
    },
    {
      name: 'Plan Completed',
      selector: row => row.plan_completed_date,
      sortable: false,
    },
    {
      name: 'Risk Done',
      selector: row => row.risk_profile_date,
      sortable: false,
    },
    {
      name: 'Shared Date',
      selector: row => row.shared_date,
      sortable: false,
    },
    {
      name: 'Freezed Date',
      selector: row => row.freezed_date,
      sortable: false,
    },
    {
      name: 'Execution Date',
      selector: row => row.execution_date,
      sortable: false,
    },
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <><span className='cursor_pointer' onClick={() => loginUser(d.id, d.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span></>,
    },
  ];
  */

  // eslint-disable-next-line
  const [relManager, setRelManager] = useState([])


  const [isActive, setActive] = useState(false);
/* 
  if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  } */

 
 
    useEffect(() => {
      setTimeout(()=>{
        // Ensure the DOM is ready and `applyDataTable` is available globally
        if (typeof window.applyDataTable === "function") {
      
            window.applyDataTable(
              '#datatable_1', 
              {
                "bFilter": true,
              } , 
              function(datatable) {
                
                // Add datatable filter relManager 
                  const fileter_relManager = document.getElementById('relManager');
                  if (fileter_relManager) {
                      fileter_relManager.addEventListener('change', function() {
                        datatable.column(2).search(this.value).draw();
                      });
                  }

                  // Add datatable filter primaryPlanner 
                  const primaryPlanner = document.getElementById('primaryPlanner');
                  if (primaryPlanner) {
                      primaryPlanner.addEventListener('change', function() {
                        datatable.column(3).search(this.value).draw();
                      });
                  }

      
              }
               
            );
        }
      },5000);
      }, [tableRowsData]); 

    const [trigger, setSidebarTrigger] = useState(false);

  return (
    <NewTheme loading={loading}> 
        <div className="page-header">
            <div className="content-page-header">
            <h5>PLAN Profile Timeline</h5>
            <div className="list-btn">
                
                <ul className="filter-list">
                    <li>
                        <button onClick={()=>setSidebarTrigger(true)} className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                            <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                        </button>
                    </li>  
                </ul> 
               
            </div>
            </div>
        </div>
        <SidebarPopupComponent title='Filter Timeline Data' id="sidebar_2" trigger={trigger} setSidebarTrigger={setSidebarTrigger}>          
          <div className='row'>

            <div className="form-group">
              <label htmlFor="relManager">REL Manager:<small style={{ fontSize: '9px' }}>(DataTable Filter)</small></label>
              <select id="relManager" className="form-control select2">
                <option value="">ALL</option>
                <option value="No Primary">No Primary</option>
                { uniqueRelManager.map(manager => (
                  <option key={manager} value={manager}>{manager}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="primaryPlanner">Primary Planner:<small style={{ fontSize: '9px' }}>(DataTable Filter)</small></label>
              <select id="primaryPlanner" className="form-control select2">
                <option value="">ALL</option>
                {uniquePlanners.map(planner => (
                  <option key={planner} value={planner}>{planner}</option>
                ))}
              </select>
            </div>
            <br/>
            <br/>
            <br/>
            <small style={{ fontSize: '9px' }}> Query Filter</small>
            <div className="form-group">
              <label htmlFor="plan_status">Plan Status:</label>
              <select id="plan_status" name="plan_status" className="form-control" onChange={handleInputChange} defaultValue={planStatus}>
                <option value="">ALL</option>
                <option value="1">In Progess</option>
                <option value="3">Plan Completed</option>
                <option value="5">Shared</option>
                <option value="7">Freezed</option>
                <option value="9">Execution Done</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="plan_mapped">Plan Mapped:</label>
              <select id="plan_mapped" name="plan_mapped" className="form-control" onChange={handleInputChange} defaultValue={planMapped}>
                <option value="">ALL</option>
                <option value="mapped">Mapped</option>
                <option value="Unmapped">Unmapped</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="plan_date">Status Filter:</label>
              <select id="plan_date" name="plan_date" className="form-control" onChange={handleInputChange} defaultValue={planDate}>
                <option value="">ALL</option>
                <option value="not_complete">Not completed 15 days</option>
                <option value="not_shared">Not shared 15 days</option>
                <option value="not_freeze">Not freeze 15 days </option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="date_filter">Select Date Filter:</label>
              <select id="date_filter" name="date_filter" className="form-control" onChange={handleInputChange} defaultValue={dateFilter}>
                <option value="created_date">Created Date</option>
                <option value="shared_date">Shared Date</option>
                <option value="execution_date">Execution Date</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="filterDate">Select Date after Dropdown:</label>
              <input type="date" name="filterDate" id="filterDate" className="form-control" onChange={handleInputChange} defaultValue={filterDate} />
            </div>

            <div className="form-group">
              <label htmlFor="plan_anniversary_years">Anniversary In Year:</label>
              <select id="plan_anniversary_years" name="plan_anniversary_years" className="form-control" onChange={handleInputChange} defaultValue={planAnniversaryYears}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="planAnniversary">Plan Anniversary:</label>
              <input type="date" name="plan_anniversary" id="plan_anniversary" className="form-control" onChange={handleInputChange} defaultValue={planAnniversary} />
            </div>


            <div className="filter-buttons">
                <button type="button" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary" onClick={handleFilter}>
                    Filter
                </button>
                <button type="button" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary"  onClick={resetFilter}>
                    Reset
                </button>
            </div>  
          </div> 
        </SidebarPopupComponent>

        <TableContainer>
            <table className="table table-stripped table-hover " id="datatable_1">
                <thead className="thead-light">
                    <tr>
                    <th title='profile_id'>ID</th>
                    <th>Name</th>
                    <th>Primary Rel Manager</th>
                    <th>Primary Planner</th>
                    <th>Plan Created</th>
                    <th>Step Completed</th>
                    <th>Plan Completed</th>
                    <th>Risk Done</th>
                    <th>Shared Date</th>
                    <th>Freezed Date</th>
                    <th>Execution Date</th>
                    <th>Action</th>                     
                    </tr>
                </thead>
                <tbody>
                    {tableRowsData.map((row, index) => {
                    return <tr key={index}>
                        <td>{row.profile_id}</td>
                        <td>
                          <h2 className="table-avatar">
                              <Link to="#" className="avatar avatar-sm me-2">
                                  <img className="avatar-img rounded-circle"  src={AvatarImage(row.gender)} alt="" /></Link>
                              <Link to="#">{row.name} <span><span className="__cf_email__" >{row.email}</span></span></Link>
                          </h2> 
                        </td>
                        <td>{row.primary_rel_manager}</td>
                        <td>{row.admin_name }</td>
                        <td>{row.created_date}</td>
                        <td>{row.steps_completed_date}</td>
                        <td>{row.plan_completed_date}</td>
                        <td>{row.risk_profile_date}</td>
                        <td>{row.shared_date}</td>
                        <td>{row.freezed_date}</td>
                        <td>{row.execution_date}</td>
                        <td>
                        <span className='cursor_pointer btn' onClick={() => loginUser(row.id, row.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span>
                        </td> 
                    </tr>;
                    })}
                </tbody>
            </table>
        </TableContainer> 

      {
        (isActive === true) && (<AddTestProfileSidebar setActive={setActive} />)
      }

    </NewTheme >
  );
}
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CardBox(props) {

  /* eslint-disable no-unused-vars */
  let navigate = useNavigate();
  const [link_url, setLinkUrl] = useState(props.link_url ? props.link_url : false);

  const click_event = (link)=>{
    if(link_url){
      window.location.href = link_url;
    }
    // link_url &&  navigate(link_url);
  }

  const [active, setActive] = useState(props.active ? ' card-box-active ' : '');

  return (
    <>
      <div className={ active +  " col-xl-2 col-lg-4 col-sm-6 col-12 pointer"} onClick={click_event}>
        <div className="card inovices-card">
          <div className="card-body">
            <div className="dash-widget-header">
              <span className="inovices-widget-icon bg-info-light">
                <img src={props.icon ? props.icon : 'assets/img/icons/receipt-item.svg'} alt="" />
              </span>
              <div className="dash-count">
                <div className="dash-title">{props.title && props.title}</div>
                <div className="dash-counts">
                  <p>{props.value ? props.value : 0}</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="inovices-all">
                {props.counter_title && props.counter_title}
                <span className={"rounded-circle " + ( props.counter_value_color && props.counter_value_color === 'danger' ? ' bg-danger-light' : ' bg-success-light')}>
                  {props.counter_value && props.counter_value}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react'
import { inWords } from '../global'
import { dbRatio, savingRatio, emiRatio } from '../global';

export default function CashflowAnalysis({ summaryData, profiletermData, error }) {

  const [data, setData] = useState({
    totalIncome: 0,
    totalExpense: 0,
    selfIncome: 0,
    spouseIncome: 0,
    rentalIncome: 0,
    investmentIncome: 0,
    bonus: 0,
    liabilities: [],
    termData: {},
    commitmentTotal: 0,
    ratioExplain: {},
    rentalExpense: 0,
    household: 0,
    childrenEducation: 0,
    utilityBills: 0,
    lifestyle: 0,
    vacation: 0,
    totalLoan: 0,
    totalLiGi: 0
  });


  const { totalIncome, totalExpense, selfIncome, spouseIncome, rentalIncome, investmentIncome, bonus, liabilities, termData, commitmentTotal, household, childrenEducation, rentalExpense, utilityBills, lifestyle, vacation, totalLoan, totalLiGi } = data;



  const [drratio, setDrratio] = useState('');
  const [emiburden, setEmiburden] = useState('');
  const [savingrate, setSavingrate] = useState('');
  const [ratioExplain, setRationExplain] = useState([]);

  useEffect(() => {

    setRationExplain(summaryData?.fp_report_ratio_explain || '');
    setDrratio(summaryData?.dr_ratio || 0);
    setEmiburden(summaryData?.emi_burden || 0);
    setSavingrate(summaryData?.saving_rate || 0);

    setData({
      totalIncome: summaryData?.incomeSum?.incomeSum || 0,
      totalExpense: summaryData?.expenseSum?.expenseSum || 0,
      selfIncome: summaryData?.incomes?.find(item => item.sub_type === "IncomeSelf")?.total_amount || 0,
      spouseIncome: summaryData?.incomes?.find(item => item.sub_type === "IncomeSpouse")?.total_amount || 0,
      rentalIncome: summaryData?.incomes?.find(item => item.sub_type === 'RentalIncome')?.total_amount || 0,
      investmentIncome: summaryData?.incomes?.find(item => item.sub_type === 'InvestmentIncome')?.total_amount || 0,
      bonus: summaryData?.incomes?.find(item => item.sub_type === 'Bonus')?.total_amount || 0,
      household: summaryData?.incomes?.find(item => item.sub_type === 'Household')?.total_amount || 0,
      rentalExpense: summaryData?.incomes?.find(item => item.sub_type === 'rentalExpense')?.total_amount || 0,
      childrenEducation: summaryData?.incomes?.find(item => item.sub_type === 'ChildrenEducation')?.total_amount || 0,
      utilityBills: summaryData?.incomes?.find(item => item.sub_type === 'UtilityBills')?.total_amount || 0,
      lifestyle: summaryData?.incomes?.find(item => item.sub_type === 'Lifestyle')?.total_amount || 0,
      vacation: summaryData?.incomes?.find(item => item.sub_type === 'Vacation')?.total_amount || 0,
      liabilities: summaryData?.liability || [],
      termData: profiletermData[0] || {},
      commitmentTotal: (parseInt(profiletermData[0]?.healthpremiumAmount) || 0) +
        (parseInt(profiletermData[0]?.spousepremiumAmount) || 0) +
        (parseInt(profiletermData[0]?.selfpremiumAmount) || 0),
      totalLoan: summaryData?.emiSum?.emiSum ? parseFloat(summaryData.emiSum.emiSum) : 0,
      totalLiGi: summaryData?.ligiSum?.lifeSum ? parseFloat(summaryData.ligiSum.lifeSum) : 0
    });



  }, [summaryData, profiletermData]);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

      <div className="font22 fw700 color0C4 pt30">Cashflow Analysis</div>

      {error && <div className="error-message text-danger font18">{error}</div>} {/* Display error if any */}

      <div className="font14 fw500 color182 mt20">
        The cash flow analysis is the projection of your Income, Expenses and Investments for the current year. A positive and consistent Cash flow is an essential to achieve your financial goals. The Savings that you make every year should be invested in such a way that you meet your financial goals.
      </div>

      <div className="inflowbox_outer mt30">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total income including returns and inheritance.</div>
          <div className="font24 fw500 color263 mt12">{totalIncome > 0 ? inWords(totalIncome) : '00'}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">
            <div>
              <div className="font16 fw500 color263">{selfIncome > 0 ? inWords(selfIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Self income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{spouseIncome > 0 ? inWords(spouseIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Spouse income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{rentalIncome > 0 ? inWords(rentalIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Rental income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{investmentIncome > 0 ? inWords(investmentIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Investment Income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{bonus > 0 ? inWords(bonus) : '00'}</div>
              <div className="font13 color7B8 fw500">Variable/Bonus income</div>
            </div>
          </div>
        </div>
      </div>


      <div className="inflowbox_outer mt15">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total expenses.</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalExpense)}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">
            <div>
              <div className="font16 fw500 color263">{inWords(household)}</div>
              <div className="font13 color7B8 fw500">HouseHold</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(childrenEducation)}</div>
              <div className="font13 color7B8 fw500">Kids Education</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(rentalExpense)}</div>
              <div className="font13 color7B8 fw500">Rental Paid</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(vacation)}</div>
              <div className="font13 color7B8 fw500">Vacation</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(utilityBills)}</div>
              <div className="font13 color7B8 fw500">Utility Bill</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(lifestyle)}</div>
              <div className="font13 color7B8 fw500">Lifestyle</div>
            </div>
          </div>
        </div>
      </div>

      <div className="inflowbox_outer mt15">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title orangedot font12 fw600 color6d7">EMI & Commitments</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total EMIs and Commitments.</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalLoan + totalLiGi)}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">




            {
              liabilities.map((data, index) => {
                return (
                  <>

                    <div>
                      <div className="font16 fw500 color263">{inWords((data.emi * 12))}</div>
                      <div className="font13 color7B8 fw500">{data.inv_name}</div>
                    </div>
                  </>
                )
              })
            }

            <div>
              <div className="font16 fw500 color263">{(!termData.healthpremiumAmount) ? '00' : inWords((termData.healthpremiumAmount * termData.healthFrequency))}</div>
              <div className="font13 color7B8 fw500">{"Health Insurance"}</div>
            </div>

            <div>
              <div className="font16 fw500 color263">{!termData.selfpremiumAmount ? '00' : inWords(parseFloat(termData.selfpremiumAmount * termData.selfFrequency))}</div>
              <div className="font13 color7B8 fw500">{"Term Insurance"}</div>
            </div>

            <div>
              <div className="font16 fw500 color263">{!termData.selfpremiumAmount ? '00' : inWords(parseFloat(termData.spousepremiumAmount * termData.spouseFrequency))}</div>
              <div className="font13 color7B8 fw500">{"Spouse Term Insurance"}</div>
            </div>


          </div>
        </div>
      </div>



      <div className="totalSaving_box mt15">
        <div>
          <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalIncome)}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">-</div>
        </div>
        <div>
          <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
          <div className="font24 fw500 color263 mt12">{inWords(parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal))}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">=</div>
        </div>
        <div>
          <div className="inflowbox_title bluedot font12 fw600 color6d7">TOTAL SAVING</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalIncome - (parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal)))}</div>
        </div>
      </div>



      <div className="font22 fw700 color0C4 mt40">Financial Health</div>


      <div className="loanBox_outer mt25 p25">
        <div className="loandtl">
          <div className="font13 fw500 color6d7">D/A RATIO <i className="fa fa-info-circle" onClick={() => dbRatio(ratioExplain)}></i></div>
          <div className="font24 fw500 color182">{drratio > 0 ? drratio + ' %' : ' 0 %'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">EMI BURDEN <i className="fa fa-info-circle" onClick={() => emiRatio(ratioExplain)}></i></div>
          <div className="font24 fw500 color182">{emiburden > 0 ? emiburden + ' %' : ' 0 %'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">SAVING RATE <i className="fa fa-info-circle" onClick={() => savingRatio(ratioExplain)}></i></div>
          <div className="font24 fw500 color182">{savingrate > 0 ? savingrate + ' %' : ' 0 %'}</div>

        </div>
      </div>


    </>
  )
}

import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BasicDetails from './ProfileStepper/BasicDetails'
import Protection from './ProfileStepper/Protection'
import Goals from './ProfileStepper/Goals'
import Cashflow from './ProfileStepper/Cashflow'
import Investments from './ProfileStepper/Investments'
import Compeleted from '../components/wizard/Compeleted'
import Header from '../components/layout/Header'
import axioInstance from '../components/axiosInstance'
import { Encryption } from '../components/global'
import { showErrorAlert } from '../components/global'
import FinnLoader from '../components/FinnLoader'

export default function ProfileStepper() {

    const navigate = useNavigate();
    const [steps, setSteps] = useState(6);
    const [lifeStage, setLifeStage] = useState(null);
    const [childCount, setChildCount] = useState(1);
    const [loading, setLoading] = useState(true);

    const [activeStep, setActiveStep] = useState();

    useEffect(() => {

        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/sign-in");
            return;
        }

        const fetchProfileData = async () => {
            try {
                const profile_id = sessionStorage.getItem('profile_id');
                const response = await axioInstance.post(`profile/selfdata`, { profile_id });

                if (response.data.status === 100) {

                    if (response.data.self_data.stepper === "1") {
                        setSteps(1);
                    } else {
                        setSteps(6);

                    }
                    sessionStorage.setItem('profile_details_id', Encryption(response.data.self_data.profile_details_id));
                    sessionStorage.setItem('current_age', response.data.self_data.current_age);
                    sessionStorage.setItem('self_dob', response.data.self_data.dob);

                    setActiveStep(response.data.self_data.stepper);

                    if (response.data.self_data.lifeStage !== null) {

                        setLifeStage(response.data.self_data.lifeStage);

                        setChildCount(
                            ["single", "married"].includes(response.data.self_data.lifeStage)
                                ? 0
                                : response.data.child_cnt || 1
                        );
                    }
                    setLoading(false);
                }


            } catch (error) {
                console.error("Error fetching profile data:", error);
                showErrorAlert(error);
                setLoading(false);
            }
        };

        fetchProfileData();

    }, [navigate]);


    const StepChange = (steps) => {
        setSteps(steps);
    }

    return (

        <Fragment>
            {loading && <FinnLoader />}
            <Header steps={steps} />
            <div className="innercontainer mb40">
                {steps === 1 && (<BasicDetails StepChange={StepChange} steps={steps} childCount={childCount} setChildCount={setChildCount} lifeStage={lifeStage} setLifeStage={setLifeStage} />)}
                {steps === 2 && (<Protection StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 3 && (<Goals StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 4 && (<Cashflow StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 5 && (<Investments StepChange={StepChange} steps={steps} lifeStage={lifeStage} setActiveStep={setActiveStep}/>)}
                {steps === 6 && (<Compeleted StepChange={StepChange} steps={steps} activeStep={activeStep}/>)}
            </div>
        </Fragment>


    )
}
import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import InvestmentInput from '../utils/InvestmentInput';

export default function PpfEpf({ active, iValues, maxCount, nextActive, investment, activeAsset, current, setCurrent, StepChange, steps, nextStep, setPopupContent, setShow, setPopupTitle }) {

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [checkboxCheck, setCheckboxCheck] = useState(true);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [investmentData, setInvestmentData] = useState({
    self: {},
    spouse: {}
  });

  const nextStepCall = () => {
    if (current + 1 === maxCount) {
      axioInstance.post(`profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), step: steps + 1 }).then(function (response, data) {
        StepChange(nextStep(steps));
      });
    } else {
      setCurrent(current + 1);
      activeAsset(iValues[current + 1].id);
    }
    setButtonSpinner(false);
  }

  const profileId = sessionStorage.getItem("profile_id");
  const profileDetailsId = sessionStorage.getItem("profile_details_id");
  const adminId = sessionStorage.getItem("admin_id");

  const handleInvestmentPost = async (data) => {

    console.log('postData', data);

    try {
      await axioInstance.post("investment/add-investment", data);
      return true;
    } catch (error) {
      setShow(true);
      setPopupTitle("Stocks");
      setPopupContent("Error adding investment. Please try again.");
      return false;
    }
  };

  const onSubmit = async (mfdata) => {

    // eslint-disable-next-line
    setButtonSpinner(true);

    // logic for no and skip to next
    if (!checkboxCheck || (mfdata.selfCurrentVal === "0" && mfdata.selfInvested === "0" && mfdata.selfInvestId === '')) {
      nextStepCall();
      return;
    }

    const postData = (relation, detailsId, CurrentVal, currentValueDigits, InvestedDigits, invested, frequency, investId) => ({
      prop_id: profileId,
      profile_id: profileId,
      profile_details_id: detailsId,
      type: 5,
      owner: profileId,
      invest_type: 0,
      sub_type: 3,
      instr_name: 'PPF/EPF',
      frequency: frequency,
      current_value: CurrentVal * currentValueDigits,
      cost_amount: CurrentVal * currentValueDigits,
      other_amount: 0,
      amount: invested * InvestedDigits,
      invest_amount: invested * InvestedDigits,
      tenure: 1,
      invest_id: investId,
      moneyDigits: currentValueDigits,
      admin_id: adminId
    });

    if (mfdata.selfCurrentVal !== 0) {
      const selfInvestment = postData("self", profileDetailsId, mfdata.selfCurrentVal, mfdata.selfValueMoneyType, mfdata.selfInvestedMoneyType, mfdata.selfInvested, mfdata.selfFrequency, mfdata.selfInvestId);

      if (await handleInvestmentPost(selfInvestment)) {

        nextStepCall();

      }
    } else {
      nextStepCall();
    }

  }

  const validateCheckbox = (checkbox, type) => {
    const isChecked = checkbox !== "no";

    if (type === "self") {
      setCheckboxCheck(isChecked);
    }
  };


  useEffect(() => {
    const fetchInvestmentData = async () => {
      try {
        const profile_id = sessionStorage.getItem('profile_id');

        const response = await axioInstance.post(`profile/get-investments`, {
          profile_id,
          type: 5,
          sub_type: 3
        });


        const selfData = response.data.find(item => item.relation === "self") || {};

        setInvestmentData({
          self: selfData
        });

      } catch (error) {
        console.error("Error fetching investment data:", error);
      }
    };

    fetchInvestmentData();
  }, []);

  return (
    <Fragment>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formtitle flex_center justify_center">
          <div>
            <div className="font20 fw600">Investment</div>
            <div className="font15 color495 ">Also a bit approximate idea about your commitments</div>
          </div>
          <div>


            <button className="bluebtn font13 fw600 d-none d-md-block" type={buttonSpinner ? "button" : "submit"}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
              <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
              </svg></span></button>
          </div>
        </div>

        <div className="pt35">

          <div className="investmentTabs mb20">

            <ul>

              {investment.map((data, i) => {

                return (data.checked === true && (<Fragment>

                  <li className={data.id}>
                    <label>
                      <input type="radio" name="assets" />
                      <div class={active === data.id ? 'investmentTabs_text act' : 'investmentTabs_text'}>{data.name}</div>
                    </label>
                  </li>
                </Fragment>)
                );
              })}
            </ul>
          </div>

        </div>

        <div className="custome_form">




          <div className="p35">

            <div className="form-row mb-2">
              <div>
                <div className="color212 font14 fw600">Do you want to add PPF/EPF? <span className="colorfa8">*</span>
                </div>
                <ul className="steps_tag font14 fw500 color182 mt15">
                  <li>
                    <label>
                      <input type="radio" name="investmentCheck" {...register("investmentCheck", { required: 'Required' })} value="yes" onClick={() => validateCheckbox('yes', 'self')} checked={checkboxCheck === true ? "checked" : ""} />
                      <span className="rdo_text_box">
                        Yes
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="investmentCheck" {...register("investmentCheck", { required: 'Required' })} value="no" onClick={() => validateCheckbox('no', 'self')} checked={checkboxCheck === false ? "checked" : ""} />
                      <span className="rdo_text_box">
                        No
                      </span>
                    </label>
                  </li>
                </ul>
                <span className="text-danger"> {errors.investmentCheck && errors.investmentCheck.message}</span>
              </div>
            </div>

            {
              checkboxCheck === true &&
              (
                <>
                  <InvestmentInput
                    register={register}
                    errors={errors}
                    namePrefix="self"
                    currentValueLabel="Current Value of your PPF/EPF"
                    periodicInvestmentLabel="Periodic investment"
                    maxZero={3}
                    investmentData={investmentData.self} // Pass data for self
                    setValue={setValue}
                  />
                </>
              )
            }


          </div>

        </div>

        <div className="saveBtn d-block d-sm-none">
          <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"} <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
        </div>
      </form>
    </Fragment>
  )
}

import React from 'react';
import './css/common.css';
//import useCheckVersion from './useCheckVersion'; // Adjust the import path as needed
//import RefreshSidebar from './RefreshSidebar';
//import axios from 'axios';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterPage from './RouterPage';
function App() {

  //const isNewVersion = useCheckVersion();

  /*const handleRefresh = () => {

    var admin_id = sessionStorage.getItem('admin_id')

    // Clear local storage
    localStorage.clear();
    sessionStorage.clear();
    // Clear cache
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    axios.get('/version.json')
      .then(response => {

        if (response.data.version) {
          localStorage.setItem('appVersion', response.data.version);
          if (admin_id) {
            window.location.href = '/ulogin';
          } else {
            window.location.href = '/sign-in';
          }
        }

      })
      .catch(error => {
        console.error(error);
      });
  };*/

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="App">
        {/*<RefreshSidebar isNewVersion={isNewVersion} onRefresh={handleRefresh} />*/}
        <RouterPage />
      </div>
    </React.Fragment>
  );
}

export default App;

import React, {   useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { Link, useNavigate } from 'react-router-dom' 
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import NewTheme from '../components/admin-dashboard/NewTheme';
import TableContainer from '../components/admin-dashboard/TableContainer';

export default function ProfileEventLogsDashboardNew() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {
 
      axioInstance.get(`admin/get-profile-event-logs`).then(
        (response, data) => {
          setData(response.data.rows);
          setTableRowsData(response.data.rows);
        }
      ).catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Turn off the loader
      });


    }
  }, [navigate]);

 

  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  useEffect(() => {
    //console.log("tableRowsData.length", tableRowsData.length);
    setTimeout(()=>{
        // Ensure the DOM is ready and `applyDataTable` is available globally
        if (typeof window.applyDataTable === "function") {
          console.log("call applyDataTable function ");
      
            window.applyDataTable(
              '#datatable_logs', 
              {
                "bFilter": true,
                "order": [ [1, 'desc'] ]
              }, 
              /* function(datatable) {
                
                // Add datatable filter relManager 
                  const fileter_relManager = document.getElementById('relManager');
                  if (fileter_relManager) {
                      fileter_relManager.addEventListener('change', function() {
                        datatable.column(2).search(this.value).draw();
                      });
                  } 
              } */
            );
        }
      },5000);
  }, [tableRowsData]);  

  
  return (
    <NewTheme loading={loading}>

        <div className="page-header">
            <div className="content-page-header">
            <h5>Profile Event Logs</h5>
            <div className="list-btn"> 
                <ul className="filter-list">
                   {/*  <li>
                        <button onClick={()=>setSidebarTrigger(true)} className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                            <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                        </button>
                    </li>   */}
                </ul>  
            </div>
            </div>
        </div>
          
        <TableContainer >
            <table className="table table-stripped table-hover " id="datatable_logs">
                <thead className="thead-light">
                    <tr>
                        <th>Action</th>
                        <th>ID</th> 
                        <th>Name</th> 
                        <th>Event Name</th> 
                        <th>Event Value</th> 
                        <th>Created By</th> 
                        <th>Created At</th>              
                    </tr>
                </thead>
                <tbody>
                    {tableRowsData.map((row, index) => {
                    return <tr key={index}>
                          <td className="text-end">
                            <div className="dropdown dropdown-action" style={{float: 'left'}}>
                                <Link  className="btn-action-icon" data-bs-toggle="dropdown"
                                    aria-expanded="false"><i className="fas fa-ellipsis-v"></i></Link>
                                <div className="dropdown-menu dropdown-menu-end"> 
                                    <Link className="dropdown-item"  to="#" >
                                    <i className="far fa-user me-2"></i> View  Profile</Link>
                                </div>
                            </div>
                        </td> 
                        <td>{row.id}</td>
                        <td>{row.full_name}</td>
                        <td>{row.event_name}</td>
                        <td>{row.event_value}</td>
                        <td>{row.createdby}</td>
                        <td>{row.created}</td> 
                    </tr>;
                    })}
                </tbody>
            </table>
        </TableContainer>   

      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

    </NewTheme>
  );
}
import React, {  useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import {  useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
//import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import PlanSummaryChart from '../components/PlanSummaryChart';
import NewTheme from '../components/admin-dashboard/NewTheme';
import PlanSummaryReportTabs from '../components/admin-dashboard/PlanSummaryReportTabs';

export default function ReportPlanSummaryDrilldownMonthNew() {

  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/

   // eslint-disable-next-line
   const [loading, setLoading] = useState(true); // Loading state


  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [expandedMonths, setExpandedMonths] = useState([]);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [rawData, setRawData] = useState([]);

  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });

  };

  const relmanagerFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.admin_id) === parseInt(e.target.value)
      ) {
        return item;
      }


    });

  }

  //plan-summary-report-drilldown-month - done
  // Update your `useEffect` to calculate grand total and append it
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {
      axioInstance.post(`admin/get-plan-summary-month-wise`, { 'rel_manager': sessionStorage.getItem('admin_id') }).then(
        (response) => {
          const transformedData = transformData(response.data.rows);
          const grandTotalRow = calculateGrandTotals(transformedData);
          setData([...transformedData, grandTotalRow]); // Append Grand Total row
          setRawData(response.data);
        }
      ).catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Turn off the loader
      });
    }
    // eslint-disable-next-line
  }, [navigate]);


  // Adjust rendering logic to skip expandable behavior for Grand Total
  const renderRow = (row) => {
    if (row.month === "Grand Total") {
      return (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>
          {/* Styling for the Grand Total row */}
          Grand Total
        </div>
      );
    }
    return (
      <div onClick={() => toggleMonth(row.month)} style={{ cursor: "pointer" }}>
        {expandedMonths.includes(row.month) ? "▼" : "▶"} {row.month}
      </div>
    );
  };


  const transformData = (data) => {
    const result = [];
    for (const month in data) {
      const monthData = {
        month,
        weeks: [],
        new_created: 0,
        plan_completed: 0,
        plan_shared: 0,
        freezed: 0,
        exection_done: 0
      };
      for (const week in data[month]) {
        const weekData = {
          week,
          managers: [],
          new_created: 0,
          plan_completed: 0,
          plan_shared: 0,
          freezed: 0,
          exection_done: 0
        };
        for (const manager in data[month][week]) {
          const managerData = {
            manager,
            ...aggregateManagerData(data[month][week][manager])
          };
          weekData.managers.push(managerData);
          weekData.new_created += managerData.new_created;
          weekData.plan_completed += managerData.plan_completed;
          weekData.plan_shared += managerData.plan_shared;
          weekData.freezed += managerData.freezed;
          weekData.exection_done += managerData.exection_done;
        }
        monthData.weeks.push(weekData);
        monthData.new_created += weekData.new_created;
        monthData.plan_completed += weekData.plan_completed;
        monthData.plan_shared += weekData.plan_shared;
        monthData.freezed += weekData.freezed;
        monthData.exection_done += weekData.exection_done;
      }
      result.push(monthData);
    }
    return result;
  };

  // Inside your component
  const calculateGrandTotals = (data) => {
    const totals = data.reduce(
      (acc, row) => ({
        new_created: acc.new_created + row.new_created,
        plan_completed: acc.plan_completed + row.plan_completed,
        plan_shared: acc.plan_shared + row.plan_shared,
        freezed: acc.freezed + row.freezed,
        exection_done: acc.exection_done + row.exection_done,
      }),
      { new_created: 0, plan_completed: 0, plan_shared: 0, freezed: 0, exection_done: 0 }
    );

    return {
      month: "Grand Total",
      new_created: totals.new_created,
      plan_completed: totals.plan_completed,
      plan_shared: totals.plan_shared,
      freezed: totals.freezed,
      exection_done: totals.exection_done,
      weeks: [], // Empty, no expansion for grand total
    };
  };


  const aggregateManagerData = (managerData) => {
    return managerData.reduce((acc, item) => ({
      new_created: acc.new_created + item.new_created,
      plan_completed: acc.plan_completed + item.plan_completed,
      plan_shared: acc.plan_shared + item.plan_shared,
      freezed: acc.freezed + item.freezed,
      exection_done: acc.exection_done + item.exection_done
    }), { new_created: 0, plan_completed: 0, plan_shared: 0, freezed: 0, exection_done: 0 });
  };

  const columns = [
    {
      name: "Month",
      cell: renderRow,
      sortable: true,
    },
    { name: "New Created", selector: (row) => row.new_created, sortable: true },
    { name: "Plan Completed", selector: (row) => row.plan_completed, sortable: true },
    { name: "Plan Shared", selector: (row) => row.plan_shared, sortable: true },
    { name: "Freezed", selector: (row) => row.freezed, sortable: true },
    { name: "Execution Done", selector: (row) => row.exection_done, sortable: true },
  ];

  // Ensure Grand Total row is not expandable
  const expandableRowDisabled = (row) => row.month === "Grand Total";



  const managerColumns = [
    { name: 'Manager', selector: manager => manager.manager, sortable: true },
    { name: 'New Created', selector: manager => manager.new_created, sortable: true },
    { name: 'Plan Completed', selector: manager => manager.plan_completed, sortable: true },
    { name: 'Plan Shared', selector: manager => manager.plan_shared, sortable: true },
    { name: 'Freezed', selector: manager => manager.freezed, sortable: true },
    { name: 'Execution Done', selector: manager => manager.exection_done, sortable: true }
  ];

  const toggleMonth = (month) => {
    setExpandedMonths(prevState => (
      prevState.includes(month) ? prevState.filter(m => m !== month) : [...prevState, month]
    ));
  };

  const toggleWeek = (month, week) => {
    const key = `${month}-${week}`;
    setExpandedWeeks(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const renderExpandedRow = (row) => (
    <div>
      {row.weeks.map(week => (
        <div key={week.week} style={{ paddingLeft: '20px' }}>
          <div onClick={() => toggleWeek(row.month, week.week)} style={{ cursor: 'pointer' }}>
            {expandedWeeks[`${row.month}-${week.week}`] ? '▼' : '▶'} Week {week.week}
          </div>
          {expandedWeeks[`${row.month}-${week.week}`] && (
            <DataTable
              columns={managerColumns}
              data={week.managers}
              noHeader
              dense
            />
          )}
        </div>
      ))}
    </div>
  );


  return (
    <NewTheme  loading={loading}>
     
        <div className="page-header">
            <div className="content-page-header">
                <h5>Plan Summary Report</h5>
                <div className="list-btn"> 
                    <ul className="filter-list">
                        <li>
                            <button /* onClick={()=>setSidebarTrigger(true)} */ className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                                <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                            </button>
                        </li>  
                    </ul>  
                </div>
            </div>
        </div>
          
          <div className='row'> 
            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


            <div className='col-md-3'>
              <div className="font14 color212 fw600">Rel Manager Filter</div>
              <select className="custom-select font14 color212 fw500" name='relManager' onChange={(e) => relmanagerFilter(e)}>
                <option value="">Select Rel Manager</option> 
              </select>

            </div>

           {/*  <div className='col-md-3'>
              <Dropdown className="mt-3">
                <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots">
                  Plan Reports
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='admin-dashboard'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Admin Dashboard</Dropdown.Item>
                  <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                  <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}


          </div>

          <PlanSummaryReportTabs /> 


          <div className="">
            <div className="dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">

                      <div>
                        <PlanSummaryChart rawData={rawData} />
                      </div>

                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={data}
                          expandableRows
                          expandableRowsComponent={({ data }) => renderExpandedRow(data)}
                          expandableRowExpanded={(row) => expandedMonths.includes(row.month)}
                          expandableRowDisabled={expandableRowDisabled} // Disable expansion for Grand Total
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
    </NewTheme>
  );
}
import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function PlanSummaryReportTabs(props) {

    const location = useLocation(); 
    // console.log("location.pathname: ", location.pathname);
    const activeBtn = 'btn-primary';
    const InActiveBtn = 'btn-outline-primary';

  return ( 
    <div className="button-group mt-3">
        <Link to="/plan-summary-report-drilldown-month" className={ " btn ml5 mt50 me-2 " + ( location.pathname === '/plan-summary-report-drilldown-month' ?  activeBtn : InActiveBtn ) }>Month Wise <i className="fas fa-arrow-right font8"></i> Weekly <i className="fas fa-arrow-right font8"></i> Rel Manager</Link>

        <Link to="/plan-summary-report-drilldown-month-relmanagerwise" className={ " btn ml5 mt50 me-2 " +  ( location.pathname === '/plan-summary-report-drilldown-month-relmanagerwise' ?  activeBtn : InActiveBtn ) }>Month Wise <i className="fas fa-arrow-right font8"></i> Rel Manager <i className="fas fa-arrow-right font8"></i> Weekly</Link>

        <Link to="/plan-summary-report-drilldown-relmanager-monthwise" className={ " btn ml5 mt50 me-2 " +  ( location.pathname === '/plan-summary-report-drilldown-relmanager-monthwise' ?  activeBtn : InActiveBtn ) }>Rel Manager <i className="fas fa-arrow-right font8"></i> Month Wise <i className="fas fa-arrow-right font8"></i> Weekly</Link>  
    </div> 
  );
}


import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2';
import axioInstance from '../axiosInstance';
import { formatDigitAmount, QuoteSection } from '../global';
import SideImg from '../../img/Protection.png';

export default function CashflowScreen3({ StepChange, setSubstep, substep, prevsubStep, nextsubStep, steps, nextStep, clientName }) {
  const { register, handleSubmit, formState: { errors, isDirty, isValid }, setValue, trigger } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  // eslint-disable-next-line
  const [propertyData, setPropertyData] = useState([]); // Stores final property list (default + API)

  const skipStep = useCallback(() => setSubstep(nextsubStep(substep, 4)), [setSubstep, nextsubStep, substep]);

  useEffect(() => {
    const profile_id = sessionStorage.getItem("profile_id");

    axioInstance.post(`investment/get-investment`, { profile_id: profile_id, type: [4] })
      .then((response) => {
        if (response.data.length > 0) {

          setPropertyData(response.data);

          response.data.forEach((property, index) => {
            setValue(`propertyArr.${index}.propertyName`, property.type_name || '', { shouldDirty: true, shouldValidate: true });
            setValue(`propertyArr.${index}.propertyCurrValue`, formatDigitAmount(property.current_value) || '', { shouldDirty: true, shouldValidate: true });
            //setValue(`propertyArr.${index}.propertyCurrValueType`, property.moneyDigits || '100000');

            setValue(
              `propertyArr.${index}.propertyCurrValueType`,
              property.current_value >= 10000000 ? "10000000" : "100000"
            );

            setValue(`propertyArr.${index}.type`, property.type || '');
            setValue(`propertyArr.${index}.sub_type`, property.sub_type || '');
            setValue(`propertyArr.${index}.invest_id`, property.invest_id ?? '', { shouldDirty: true, shouldValidate: true });
          });

          trigger();
        }
      })
      .catch(() => {
        //defaultPropertiesRe();
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    setButtonSpinner(true);
    const profileId = sessionStorage.getItem('profile_id');
    const profileDetailsId = sessionStorage.getItem('profile_details_id');
    const adminId = sessionStorage.getItem('admin_id');

    try {
      const PostData = {
        prop_id: profileId,
        profile_id: profileId,
        profile_details_id: profileDetailsId,
        owner: profileId,
        frequency: 0,
        admin_id: adminId,
        properties: data.propertyArr
      };

      const response = await axioInstance.post(`profile/add-property`, PostData);

      if (response.data.status === 100) {
        sessionStorage.setItem("expenseData", JSON.stringify(data));
        setSubstep(nextsubStep(substep, 4));
      } else {
        SweetAlert.fire('Property', 'Failed. Something went wrong. Retry again', 'error');
      }
    } catch (error) {
      SweetAlert.fire('Property', 'Failed. Something went wrong. Retry again', 'error');
    } finally {
      setButtonSpinner(false);
    }
  };

  return (
    <Fragment>
      <div className="mainwrapper">
        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Cashflow</div>
          <div className="font15 color495">We need to map a plan to match your goal. Please provide your income info.</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li>1</li>
              <li onClick={() => setSubstep(prevsubStep(substep))}>2</li>
              <li className="act">3</li>
              <li>4</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Properties</div>
                  <div className="font15 color495">Provide an approximate idea about your commitments</div>
                </div>
                <button
                  type={buttonSpinner ? "button" : "submit"}
                  className="bluebtn font13 fw600 d-none d-md-block"
                  disabled={!isDirty || !isValid}
                >
                  {buttonSpinner ? <><i className="fa fa-spinner fa-spin"></i> Loading</> : "Save"}
                </button>
              </div>

              <div className="p35">
                {propertyData.map((e, i) => (
                  <div key={e.id || i} className="form_prnt">
                    <div className="form-row justify-content-between">
                      <div className="col-md-6 pr20">
                        <label className="font14 fw600">Property Name</label>
                        <input
                          type="text"
                          className="form-control font14 color212 fw500"
                          placeholder="Enter property name"
                          {...register(`propertyArr.${i}.propertyName`)}
                        />
                        <span className="text-danger">{errors.propertyArr?.[i]?.propertyName?.message}</span>
                      </div>

                      <div className="col-md-6 pl20">
                        <label className="font14 fw600">Property Current Value</label>
                        <div className="input-group selectarrow select50">
                          <input
                            type="text"
                            className="form-control font14 color212 fw500"
                            placeholder="Enter"
                            {...register(`propertyArr.${i}.propertyCurrValue`)}
                          />
                          <select className="custom-select" {...register(`propertyArr.${i}.propertyCurrValueType`)}>
                            <option value="100000">Lakhs</option>
                            <option value="10000000">Crores</option>
                          </select>
                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                      </div>

                      <input type="hidden" {...register(`propertyArr.${i}.propertyType`)} />
                      <input type="hidden" {...register(`propertyArr.${i}.type`)} />
                      <input type="hidden" {...register(`propertyArr.${i}.sub_type`)} />
                      <input type="hidden" {...register(`propertyArr.${i}.invest_id`)} />
                    </div>
                    <div className="formdevider mt25 mb25"></div>
                  </div>
                ))}
              </div>
            </form>

            <div className="mob_left_right mb5 text-center">
              <button type="button" className="btn bluebtn backtowizardBtn font13 fw600" onClick={skipStep}>
                Skip Properties
              </button>
            </div>
          </div>

          <QuoteSection
            text="If you have other expenses, please click add expenses button and add them."
            imageName={SideImg}
            altText="Cashflow"
          />

        </div>
      </div>
    </Fragment>
  );
}

import React, { useEffect, useState } from "react";  
import AdminDashboardHeaderNew from "./AdminDashboardHeaderNew";
import AdminDashboardSidebarMenuNew from "./AdminDashboardSidebarMenuNew";
import Loader from "./Loader";
import DynamicStyles from "./DynamicStyles";
import DynamicScripts from "./DynamicScripts";
/* 
const NewTheme = ({ children, loading = false }) => {
return ( 
        <div className="main-wrapper"> 
          <AdminDashboardHeaderNew />
          <AdminDashboardSidebarMenuNew />
          <div className="page-wrapper">
            <div className="content container-fluid">
              {loading ? (
                <Loader />
              ) : (
                <>{children}</>
              )}
            </div>
          </div>
        </div> 
    );  
};
export default NewTheme; */

 
const NewTheme = ({ children, loading = false }) => {

  const styles_arr = [
    '/assets/css/bootstrap.min.css',
    '/assets/plugins/fontawesome/css/fontawesome.min.css',
    '/assets/plugins/fontawesome/css/all.min.css',
    '/assets/plugins/feather/feather.css',
    '/assets/css/bootstrap-datetimepicker.min.css' ,
    '/assets/plugins/datatables/datatables.min.css',
    '/assets/css/style.css',
    '/assets/css/my-style.css',
  ];

  const scripts_arr = [
        '/assets/js/layout.js', 
        
        // "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js", 
        //"/assets/js/jquery.min-3.7.0.js",

        //"/assets/js/bootstrap.bundle.min.js"
         "/assets/plugins/datatables/datatables.min.js",
        "/assets/plugins/select2/js/select2.min.js",
        "/assets/plugins/slimscroll/jquery.slimscroll.min.js",
         //"/assets/plugins/moment/moment.min.js",
        "/assets/js/bootstrap-datetimepicker.min.js", 
        "/assets/js/jquery-ui.min.js", 
      // '/assets/js/theme-settings.js',    
        '/assets/js/greedynav.js', 
        
      /*  'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/js/bootstrap-datetimepicker.min.js', */

        "/assets/js/script.js" ,
        "/assets/js/my-script.js" 
  ];

 const scripts_arr2 = [ 
    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js", 
    "/assets/plugins/moment/moment.min.js",
    "/assets/js/bootstrap.bundle.min.js",
  ];  
 

  const scriptsToRemove = [
    'https://code.jquery.com/jquery-3.2.1.slim.min.js',
  ];

  const[assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect (() => { 
    setTimeout(() => {
      setAssetsLoaded(true); 
      document.body.classList.add("mini-sidebar"); 
    }, 2000); 
  }, []);
     
  return (
          <>
            <DynamicStyles styles={styles_arr} /> 
            <DynamicScripts scripts={scripts_arr2} removeScriptSrcs={[scriptsToRemove]} />
            {
                assetsLoaded ?  
                    <div className="main-wrapper"> 
                      <AdminDashboardHeaderNew />
                      <AdminDashboardSidebarMenuNew />
                      <DynamicScripts scripts={scripts_arr} removeScriptSrcs={[]} />
                      <div className="page-wrapper">
                        <div className="content container-fluid">
                          {loading ? (
                            <Loader />
                          ) : (
                            <>{children}</>
                          )}
                        </div>
                      </div>
                    </div> : <Loader />
            } 
          </>
        );
  
};

export default NewTheme;
 

import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { Link, useNavigate } from 'react-router-dom' 
import SweetAlert from 'sweetalert2' 
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import NewTheme from '../components/admin-dashboard/NewTheme';
import TableContainer from '../components/admin-dashboard/TableContainer';
import { AvatarImage } from '../components/global';

export default function GuestUsersDashboardNew() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true); // Loading state

  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {



      axioInstance.post(`admin/guest-users`).then(
        (response, data) => {
          setData(response.data);
          setTableRowsData(response.data);
        }
      ).catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Turn off the loader
      });


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);


  const getViewLink = (ref_url) => {



    if (ref_url) {

      SweetAlert.fire({
        title: "REF URL",
        text: ref_url ? ref_url : 'NA',
        icon: "success"
      });

    } else {
      SweetAlert.fire({
        title: "REF URL",
        text: "NA",
        icon: "error"
      });
    }




  }

 /*  const customStyles = {
    rows: {
      style: {
        minHeight: '65px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for the head cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows wrapping for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows text wrapping
        wordBreak: 'break-word', // ensures long words are broken
      },
    },
  }; */

/* 
  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="primary" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            (d.profile_created != null && d.profile_created !== '' && d.profile_details_created != null && d.profile_details_created !== '' && d.prof_goal_summary_created != null && d.prof_goal_summary_created !== '' && d.risk_profile_default_created != null && d.risk_profile_default_created !== '') ? <><Dropdown.Item > <i className="fas fa-user text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Already Verified </Dropdown.Item></> : <><Dropdown.Item onClick={() => VerifyGuestUser(d.id, d.full_name, d.mobile, d.email, d.password, d.created_by, d.profile_created, d.user_id, d.ref_url, d.utm_source, d.utm_campaign, d.utm_id)}> <i className="fas fa-user text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Verify User</Dropdown.Item></>
          }
          <Dropdown.Item onClick={() => getViewLink(d.ref_url)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> View URL</Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown></>,
    },
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      maxWidth: '100px', // restrict column width
    },
    {
      name: 'Name',
      selector: row => row.full_name,
      sortable: true,
      width: '265px' // Set the width of the column
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: '265px' // Set the width of the column
    },
    {
      name: 'Phone',
      selector: row => row.mobile,
      sortable: true,
      width: '180px' // Set the width of the column
    },
    {
      name: 'Created On',
      selector: row => row.created,
      sortable: true,
    },
    {
      name: 'Created By',
      selector: row => row.admin_name,
      sortable: true,
    },
    {
      name: 'Plan Status',
      selector: row => row.full_name,
      sortable: true,
      cell: row => {


        let profile_created;
        let profile_created_color = 'redtext';

        let profile_details_created;
        let profile_details_created_color = 'redtext';

        let prof_goal_summary_created;
        let prof_goal_summary_created_color = 'redtext';

        let risk_profile_default_created;
        let risk_profile_default_created_color = 'redtext';

        if (row.profile_created != null && row.profile_created !== '') {
          profile_created_color = 'greentext';
          profile_created = 'profile_id';
        } else {
          profile_created = 'profile_id';
        }

        if (row.profile_details_created != null && row.profile_details_created !== '') {
          profile_details_created_color = 'greentext';
          profile_details_created = 'profile details';
        } else {
          profile_details_created = 'profile details';
        }

        if (row.prof_goal_summary_created != null && row.prof_goal_summary_created !== '') {
          prof_goal_summary_created_color = 'greentext';
          prof_goal_summary_created = 'summary';
        } else {
          prof_goal_summary_created = 'summary';
        }

        if (row.risk_profile_default_created != null && row.risk_profile_default_created !== '') {
          risk_profile_default_created_color = 'greentext';
          risk_profile_default_created = 'risk';
        } else {
          risk_profile_default_created = 'risk';
        }


        return (
          <>
            <>
              <span><span className={profile_created_color}>{profile_created} </span> <br /> <span className={profile_details_created_color}>{profile_details_created} </span> <br /> <span className={prof_goal_summary_created_color}>{prof_goal_summary_created} </span> <br /> <span className={risk_profile_default_created_color}>{risk_profile_default_created} </span></span>
            </>
          </>
        );
      },
      width: '265px', // Set the width of the column
    },
    {
      name: 'UTM Campaign',
      selector: row => row.utm_campaign,
      sortable: true,
    },
    {
      name: 'UTM Source',
      selector: row => row.utm_source,
      sortable: true,
    },
    {
      name: 'UTM Id',
      selector: row => row.utm_id,
      sortable: true,
    },
    {
      name: 'IP',
      selector: row => row.user_ip,
      sortable: true,
    },
    {
      name: 'ref_url',
      selector: row => row.ref_url,
      sortable: true,
      maxWidth: '100px'
    }

  ]; */


  const VerifyGuestUser = (guest_id, fullName, mobileNumber, emailAddress, password, created_by, profile_created = '', user_id = '', lead_url, utm_source, utm_campaign, utm_id) => {

    SweetAlert.fire({
      title: profile_created != null && profile_created !== '' ? "Incomplete Profile" : "Verify User",
      text: profile_created != null && profile_created !== '' ? "Are you sure to verify this incomplete profile ?" : "Are you sure to verify this user as profile ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const Postdata = {
          full_name: fullName,
          name: fullName, // Keeping both for clarity, if needed
          mobile: mobileNumber,
          email: emailAddress,
          password: password,
          guest_id: guest_id || null, // Adding guestId if available
          register: 1, // Register flag
          registerVia: 1, // Register method flag
          created_by: created_by || null,// Relationship manager ID,
          delete_user: profile_created != null && profile_created !== '' ? '1' : '0',
          lead_url: lead_url,
          user_id: user_id, // used for delete user
          utm_source: utm_source,
          utm_campaign: utm_campaign,
          utm_id: utm_id
        };

        axioInstance.post(`admin/approve-guest-user`, Postdata).then(
          function (response) {
            console.log(response.data);

            if (response.data.get_plan_details_response.status === 100) {
              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Guest User is Verified as Profile',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                }
              }).then(() => {
                window.location.href = "admin-dashboard";
              })
            } else {
              SweetAlert.fire({
                toast: true,
                icon: 'error',
                title: response.data.message,
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                }
              });
            }

          }
        );

      }
    });

  }

// eslint-disable-next-line
  const [title, setTitle] = useState("");

  /* const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.full_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.mobile
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };
 */



  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

 
  useEffect(()=>{ 
    setTimeout(()=>{
      if (typeof window.applyDataTable === "function") {
        window.applyDataTable(
          '#datatable_guest_user', 
          {
          "bFilter": true,
          "order": [ [1, 'desc'] ]
          }
        )
      }  
    }, 5000)
   
}, [tableRowsData])  
/* 
  const addProfile = () => {

    console.log('hostname', window.location);

    const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  } */

/* 
  const planLogsRedirect = () => {
    window.location.href = 'plan-login-logs-dashboard';
    return;
  }

  const planEventsRedirect = () => {
    window.location.href = 'profile-event-logs-dashboard';
    return;
  }

  const AdminDashboardRedirect = () => {
    window.location.href = 'admin-dashboard';
    return;
  }
 */
  return (
    <>
      <NewTheme loading={loading}>  
      <div className="page-header"> 
          <div className="content-page-header">
              <h5>Guest Users Dashboard</h5>
              <div className="list-btn">
                  {/* <ul className="filter-list">
                      <li>
                          <Link className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                              <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                          </Link>
                      </li>  
                  </ul> */}
              </div>
          </div>
      </div> 


      <TableContainer>
        <table className="table table-stripped table-hover" id="datatable_guest_user">
            <thead className="thead-light">
                <tr>   
                  <th>Action</th>  
                  <th>ID</th> 
                  <th>Name | Email</th>
                  <th>Phone</th> 
                  <th>Created At</th>
                  <th>Created By</th>
                  <th>Plan Status </th>
                  <th>UTM Campaign</th>
                  <th>UTM Source</th>
                  <th>UTM ID</th>
                  <th>IP</th> 
                </tr>
            </thead>
            <tbody>
              {
                tableRowsData.map((row, index)=>{ 


                  let profile_created;
                  let profile_created_color = 'danger';
          
                  let profile_details_created;
                  let profile_details_created_color = 'danger';
          
                  let prof_goal_summary_created;
                  let prof_goal_summary_created_color = 'danger';
          
                  let risk_profile_default_created;
                  let risk_profile_default_created_color = 'danger';
          
                  if (row.profile_created != null && row.profile_created !== '') {
                    profile_created_color = 'success';
                    profile_created = 'profile_id';
                  } else {
                    profile_created = 'profile_id';
                  }
          
                  if (row.profile_details_created != null && row.profile_details_created !== '') {
                    profile_details_created_color = 'success';
                    profile_details_created = 'profile details';
                  } else {
                    profile_details_created = 'profile details';
                  }
          
                  if (row.prof_goal_summary_created != null && row.prof_goal_summary_created !== '') {
                    prof_goal_summary_created_color = 'success';
                    prof_goal_summary_created = 'summary';
                  } else {
                    prof_goal_summary_created = 'summary';
                  }
          
                  if (row.risk_profile_default_created != null && row.risk_profile_default_created !== '') {
                    risk_profile_default_created_color = 'success';
                    risk_profile_default_created = 'risk';
                  } else {
                    risk_profile_default_created = 'risk';
                  }

                  return <tr key={index}> 
                      
                      <td className="text-end">
                          <div className="dropdown dropdown-action" /*  style={{float: 'left'}} */> 
                              <Link  className="btn-action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></Link>
                              <div className="dropdown-menu dropdown-menu-end">

                                {
                                    (
                                      row.profile_created != null && row.profile_created !== '' && row.profile_details_created != null && row.profile_details_created !== '' && row.prof_goal_summary_created != null && row.prof_goal_summary_created !== '' && row.risk_profile_default_created != null && row.risk_profile_default_created !== '') ?
                                      <Link  className="dropdown-item" > <i className="far fa-user me-2"></i> Already Verified </Link> :
                                      <>
                                        <Link  className="dropdown-item"  onClick={() => VerifyGuestUser(row.id, row.full_name, row.mobile, row.email, row.password, row.created_by, row.profile_created, row.user_id, row.ref_url)}>  
                                      <i className="far fa-user me-2"></i> Verify User</Link>
                                      </> 
                                  }

                                  <Link className="dropdown-item"  onClick={() => getViewLink(row.ref_url)} >
                                  <i className="far fa-paste me-2"></i>View Url</Link> 
                                
                              </div>
                          </div>  
                      </td>  
                      <td>{row.id}</td> 
                      <td>
                      <h2 className="table-avatar">
                          <Link href="profile.html" className="avatar avatar-sm me-2">
                          <img className="avatar-img rounded-circle" src={AvatarImage(row.gender ? row.gender : '')} alt="" /></Link>
                          <Link href="profile.html">{row.full_name} <span><span className="__cf_email__" >{row.email}</span></span></Link>
                      </h2>  
                      </td>
                      <td>{row.mobile}</td> 
                      <td>{row.created }</td> 
                      <td>{row.admin_name }</td> 
                      <td> 
                        <span className={"badge bg-"+profile_created_color+"-light"}>{profile_created} </span> 
                        <br/><span className={"badge bg-"+profile_details_created_color+"-light"}>{profile_details_created} </span> 
                        <br/><span className={"badge bg-"+prof_goal_summary_created_color+"-light"}>{prof_goal_summary_created} </span> 
                        <br/><span className={"badge bg-"+risk_profile_default_created_color+"-light"}>{risk_profile_default_created} </span> 
                      </td> 
                      <td>{row.utm_campaign }</td> 
                      <td>{row.utm_source }</td> 
                      <td>{row.utm_id }</td> 
                      <td>{row.user_ip }</td>  
                  </tr> 
                })
              } 
            </tbody>
        </table>
      </TableContainer> 
 
      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      } 

    </NewTheme>
    </>
  );
}
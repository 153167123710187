import {  useEffect, useState } from "react";
import NewTheme from "../components/admin-dashboard/NewTheme";
import TableContainer from "../components/admin-dashboard/TableContainer";
import { Link } from "react-router-dom";

export default function BlankPage() {

    /* eslint-disable no-unused-vars */
    const [loading, setLoading] = useState(false);

    useEffect(()=>{ 
        if (typeof window.applyDataTable === "function") {
          window.applyDataTable(
            '#datatable_1', 
            {
            "bFilter": true,
            }
          )
        }  
    }, [])

    return (
        <NewTheme loading={loading}>  
        <div className="page-header"> 
            <div className="content-page-header">
                <h5>Blank Page </h5>
                <div className="list-btn">
                    <ul className="filter-list">
                        <li>
                            <Link className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                                <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                            </Link>
                        </li>  
                    </ul>
                </div>
            </div>
        </div>
  
        <TableContainer>
          <table className="table table-stripped table-hover " id="datatable_1">
                <thead className="thead-light">
                    <tr>
                        <th>Col-1</th>
                        <th>Col-2</th>
                        <th>Col-3</th>
                        <th>Col-4</th>
                        <th>Col-5</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>col-1.1</td>
                        <td>col-1.2</td>
                        <td>col-1.3</td>
                        <td>col-1.4</td>
                        <td>col-1.5</td> 
                    </tr>
                    <tr>
                        <td>col-2.1</td>
                        <td>col-2.2</td>
                        <td>col-2.3</td>
                        <td>col-2.4</td>
                        <td>col-2.5</td> 
                    </tr>
                </tbody> 
            </table>
        </TableContainer>
    </NewTheme>
    )
}
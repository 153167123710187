import React, {  useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { Link, useNavigate } from 'react-router-dom'
// import DataTable from 'react-data-table-component';
// import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
// import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import EditAdminUser from '../components/EditAdminUser';
import AddAdminUser from '../components/AddAdminUser';
import { Encryption } from '../components/global';
import NewTheme from '../components/admin-dashboard/NewTheme';
import TableContainer from '../components/admin-dashboard/TableContainer'; 
import SidebarPopupComponent from '../components/admin-dashboard/SidebarPopupComponent';
import AdminMarkActiveInactive from '../components/admin-dashboard/MarkAdminActiveInactive'; 

export default function ManageAdminUsersNew() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [adminStatus, setAdminStatus] = useState('');
  const [tableRowsData, setTableRowsData] = useState([]);

  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const [adminId, setAdminId] = useState();


  const editAdmin = (adminId) => {
    setActive(true);
    setAction('edit');
    setAdminId(adminId);
  }

  const addAdmin = () => {
    setActive(true);
    setAction('add');
  }

  const markActiveInactiveAdmin = (adminId) => {
    setActive(true);
    setAction('mark_active_inactive');
    setAdminId(adminId);
  }


  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const status = params.get('status') ? params.get('status') : '';
      setAdminStatus(status)
      axioInstance.get(`admin-users?status=`+status,).then(
        (response, data) => {
          setData(response.data.data);
          setTableRowsData(response.data.data);
        }
      );


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);


  // Function to delete an admin with confirmation
  const deleteAdmin = (adminId) => {
    // Show confirmation dialog
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with deletion if confirmed
        axioInstance.post(`admin-users-delete`, { admin_id: Encryption(adminId) })
          .then(response => {
            if (response.data.success) {
              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Admin deleted successfully.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then(() => {
                window.location.reload();
              });
            } else {
              SweetAlert.fire({
                toast: true,
                icon: 'error',
                title: 'Unable to delete Admin. Something went wrong.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
            }
          })
          .catch(function (error) {
            // Handle error
            let errorMessage = 'Unable to delete Admin. Something went wrong.';
            if (error.response && error.response.data && error.response.data.message) {
              errorMessage = error.response.data.message;
            }
            SweetAlert.fire({
              toast: true,
              icon: 'error',
              title: errorMessage,
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          });
      } else {
        // If the user cancels, show a message or do nothing
        SweetAlert.fire({
          toast: true,
          icon: 'info',
          title: 'Admin deletion cancelled.',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  }

/*
  const customStyles = {
    rows: {
      style: {
        minHeight: '65px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for the head cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows wrapping for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows text wrapping
        wordBreak: 'break-word', // ensures long words are broken
      },
    },
  };


  
  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="primary" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>

          <Dropdown.Item onClick={() => editAdmin(d.admin_id)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => deleteAdmin(d.admin_id)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete</Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown></>,
    },
    {
      name: 'ID',
      selector: row => row.admin_id,
      sortable: true,
      maxWidth: '50px', // restrict column width
    },
    {
      name: 'Admin Name',
      selector: row => row.admin_name,
      sortable: true,
      width: '205px' // Set the width of the column
    },
    {
      name: 'Email',
      selector: row => row.email_id,
      sortable: true,
      width: '205px' // Set the width of the column
    },
    {
      name: 'Password',
      selector: row => row.pass,
      sortable: true,
      width: '150px' // Set the width of the column
    },
    {
      name: 'Role',
      selector: row => row.role === 1 ? 'Planner' : 'Admin',
      sortable: true,
    },
    {
      name: 'report_to',
      selector: row => row.report_to_name,
      sortable: true,
    },
    {
      name: 'rel_manager',
      selector: row => row.rel_manager,
      sortable: true,
    },
    {
      name: 'ser_manager',
      selector: row => row.ser_manager,
      sortable: true,
    },
    {
      name: 'created_by',
      selector: row => row.created_by,
      sortable: true,
    },
    {
      name: 'Created',
      selector: row => row.created_on,
      sortable: true,
    }

  ];


  const [title, setTitle] = useState("");
   const onChange = async (e) => {
    setTitle(e.target.value);

    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.admin_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email_id
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }



    });
    setTableRowsData(searchData);
  };
 

   const AdminDashboardRedirect = () => {
    window.location.href = 'admin-dashboard';
    return;
  } */

    
    const adminStatusFilter = async (e) => {

      const selectedValue = e.target.value;
      setAdminStatus(selectedValue);
      const url = new URL(window.location.href);
      url.searchParams.set('status', selectedValue);
      window.location.href = url.toString();
  
    }

   /* eslint-disable no-unused-vars */
   const [loading, setLoading] = useState(false);

   const [uniqueRelManager, setUniqueRelManager] = useState([]) 
   const [uniqueSerManager, setUniqueSerManager] = useState([]) 
   const [uniqueReportTo, setUniqueReportTo] = useState([]) 

  

useEffect(() => {  
 
  setUniqueRelManager([...new Set(tableRowsData.map(item => item.rel_manager))].filter(manager => manager).sort((a, b) => a.localeCompare(b)));
  setUniqueSerManager([...new Set(tableRowsData.map(item => item.ser_manager))].filter(ser_manager => ser_manager).sort((a, b) => a.localeCompare(b)));
  setUniqueReportTo([...new Set(tableRowsData.map(item => item.report_to_name))].filter(report_to_name => report_to_name).sort((a, b) => a.localeCompare(b)));
    // Ensure the DOM is ready and `applyDataTable` is available globally
   setTimeout(()=>{
      if (typeof window.applyDataTable === "function") { 
        window.applyDataTable(
          '#datatable_admin_users', 
          {
            "bFilter": true,
            "order": [ [1, 'desc'] ]
          }, 
          function(datatable) { 

            // Add custom filtering logic
            const adminFilterInput = document.getElementById('admin_name_filter');
              if (adminFilterInput) {
                  adminFilterInput.addEventListener('keyup', function() {
                    datatable.column(2).search(this.value).draw();
                  });
              }  
              const roleFilterInput = document.getElementById('admin_role_filter');
              if (roleFilterInput) {
                roleFilterInput.addEventListener('change', function() {
                    datatable.column(5).search(this.value).draw();
                  });
              }  

              const statusFilterInput = document.getElementById('admin_status_filter');
              if (statusFilterInput) {
                statusFilterInput.addEventListener('change', function() {
                    if(this.value === 'no'){ 
                      datatable.column(10).search('^$', true, false).draw();
                    }else{
                      datatable.column(10).search(this.value).draw();
                    }
                  });
              }
              
              const filter_relManager = document.getElementById('filter_relManager');
              if (filter_relManager) {
                filter_relManager.addEventListener('change', function () {
                  if(this.value === 'no'){ 
                    datatable.column(7).search('^$', true, false).draw();
                  }else{
                    datatable.column(7).search(this.value).draw();
                  }
                  
                });
              }

              const filter_serManager = document.getElementById('filter_serManager');
              if (filter_serManager) {
                filter_serManager.addEventListener('change', function () {
                  if(this.value === 'no'){ 
                    datatable.column(8).search('^$', true, false).draw();
                  }else{
                    datatable.column(8).search(this.value).draw();
                  }
                });
              }

              const filter_reportTo = document.getElementById('filter_reportTo');
              if (filter_reportTo) {
                filter_reportTo.addEventListener('change', function () {
                  if(this.value === 'no'){ 
                    datatable.column(6).search('^$', true, false).draw();
                  }else{
                      datatable.column(6).search(this.value).draw();
                    }
                });
              }

          }

        );
    }
   }, 5000) 
  }, [tableRowsData]); // Empty dependency array ensures this runs only once on mount

    
const [trigger, setSidebarTrigger] = useState(false);

const resetFilter = () => { 
    window.location.href = 'manage-admin-users'; 
  }
 

  return (
    <NewTheme loading={loading}>    
        <div className="page-header"> 
            <div className="content-page-header">
                <h5>Manage Admin Users</h5>
                <div className="list-btn">
                    <ul className="filter-list">
                        <li className='me-2'>
                            <button type="button" className="btn btn-primary btn-block btnFinno" onClick={() => { addAdmin() }}> Add Admin User </button>
                        </li>
                        <li>
                            <Link className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                                <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                            </Link>
                        </li>  
                    </ul>
                </div>
            </div>
        </div>
 

        <TableContainer>
            <table className="table table-stripped table-hover " id="datatable_admin_users">
                <thead className="thead-light">
                    <tr>   
                        <th>Action</th>  
                        <th>ID</th>   
                        <th>Admin Name</th>   
                        <th>Email</th>   
                        <th>Password</th>   
                        <th>Role</th>
                        <th>Report To</th>
                        <th>Rel Manager</th>
                        <th>Service Manager</th>
                        <th>Profile Mapped</th>
                        <th title="users_admin.status">Status</th>
                        <th>Created BY</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    tableRowsData && tableRowsData.map((row, index)=>
                        { 
                        let color = row.email_verified === "verified" ? 'green' : 'red'; 
                        return <tr key={index}> 
                                    <td className="text-end">
                                        <div className="dropdown dropdown-action" style={{float: 'left'}}>
                                            <Link  className="btn-action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></Link>
                                            <div className="dropdown-menu dropdown-menu-end"> 
                                                <Link className="dropdown-item" onClick={() => editAdmin(row.admin_id)}>
                                                <i className="fas fa-id-card me-2"></i>Edit</Link> 
                                                <Link className="dropdown-item" onClick={() => deleteAdmin(row.admin_id)}>
                                                <i className="far fa-trash-alt me-2"></i>Delete</Link> 
                                                <Link className="dropdown-item" onClick={() => markActiveInactiveAdmin(row.admin_id)}><i className="fa fa-user me-2"></i>Mark Active/Inactive</Link> 
                                            </div>
                                        </div>
                                    </td>
                                    <td>{row.admin_id}</td>
                                    <td>{row.admin_name}</td>
                                    <td>{row.email_id}</td>
                                    <td>{row.pass}</td>
                                    <td>{row.role === 1 ? 'Planner' : 'Admin'}</td>
                                    <td>{row.report_to_name}</td>
                                    <td>{row.rel_manager}</td>
                                    <td>{row.ser_manager}</td>
                                    <td title={"Total profile mapped count: " + row.profile_mapped_count}>{row.profile_mapped_count > 0 ? 'Yes' : 'No'}</td>
                                    <td>{row.users_admin_status === 1 ? <span className="badge bg-success-light">Active</span> : <span className="badge bg-danger-light">Inactive</span>}</td>
                                    <td>{row.created_by}</td>
                                    <td>{row.created_on}</td>
                                </tr>
                        })
                    } 
                </tbody>
            </table> 
        </TableContainer>
 
        <SidebarPopupComponent id="sidebar_1"  title='Filter Admin Users Data' trigger={trigger} setSidebarTrigger={setSidebarTrigger}>  
            <div className="form-group">
                <label>Name - (<small>datatable filter</small>)</label>
                <input type="text" className="admin_name_filter form-control"  id="admin_name_filter" placeholder="Search Admin Name" />
            </div> 
            <div className="form-group">
                <label>Role - (<small>datatable filter</small>)</label>
                <select type="text" className="admin_role_filter form-control"  id="admin_role_filter">
                    <option value="">ALL</option>
                    <option value="Planner">Planner</option>
                    <option value="Admin">Admin</option> 
                </select>
            </div> 

            <div className='form-group'>
              <label>Report To Filter - (<small>datatable filter</small>)</label>
                <select className="custom-select form-control" name='reportTo' id="filter_reportTo" /* onChange={(e) => relmanagerFilter(e)} */ > 
                  <option value="">ALL</option>
                  <option value="no">No Report To</option>
                    {uniqueReportTo.map(manager => (
                        <option key={manager} value={manager}>{manager}</option>
                    ))}
                </select> 
              </div>

             <div className='form-group'>
              <label>Rel Manager Filter - (<small>datatable filter</small>)</label>
                <select className="custom-select form-control" name='relManager' id="filter_relManager" /* onChange={(e) => relmanagerFilter(e)} */ > 
                  <option value="">ALL</option>
                  <option value="no">No Rel Manager</option>
                    {uniqueRelManager.map(manager => (
                        <option key={manager} value={manager}>{manager}</option>
                    ))}
                </select> 
              </div>

              <div className='form-group'>
                <label>Service Manager Filter - (<small>datatable filter</small>)</label>
                <select className="custom-select form-control" name='serManager' id="filter_serManager" /* onChange={(e) => relmanagerFilter(e)} */ > 
                  <option value="">ALL</option>
                  <option value="no">No Service Manager</option>
                    {uniqueSerManager.map(manager => (
                        <option key={manager} value={manager}>{manager}</option>
                    ))}
                </select> 
              </div> 

             <div className="form-group">
                <label>Select User type  - (<small>query filter</small>)</label>
                <select type="text" className="admin_status_filter form-control"  id="admin_status_filter" onChange={(e) => adminStatusFilter(e)} defaultValue={adminStatus}>
                    <option value="">-- Select --</option>
                    <option value="all">ALL</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option> 
                </select>
            </div>  

            <div className="filter-buttons"> 
                <button type="button" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary" onClick={resetFilter} >
                    Reset
                </button>
            </div>     
        </SidebarPopupComponent>
 
        {
          (isActive === true && action === 'mark_active_inactive') && (<AdminMarkActiveInactive setActive={setActive} setAction={setAction} adminId={adminId} />)
        }
        {
            (isActive === true && action === 'edit') && (<EditAdminUser setActive={setActive} setAction={setAction} adminId={adminId} />)
        }

        {
            (isActive === true && action === 'add') && (<AddAdminUser setActive={setActive} setAction={setAction} />)
        }

    </NewTheme> 
  );
}
import React, { Fragment, useState } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import { Encryption } from './global'

export default function AddTestProfileSidebar({ setActive }) {

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const onSubmit = FormData => {

        setButtonSpinner(true);

        axioInstance.post(`profile/view`, { 'email': Encryption(FormData.emailAddress), 'validate': '1' }).then(function (response, data) {

            if (response.data.status === 100) {
                setError('emailAddress', { type: 'custom', message: response.data.msg });
                setButtonSpinner(false);
            } else {

                const postData = {
                    "name": FormData.fullName,
                    "mobile": FormData.mobileNumber,
                    "email": FormData.emailAddress,
                    "password": FormData.password,
                    "register": 1,
                    "testProfile": 1,
                    "registerVia": 1,
                    "rel_manager": sessionStorage.getItem('admin_id')
                };

                axioInstance.post(`add-testing-profile`, postData)
                    .then(function (response) {

                        setButtonSpinner(false);

                        if (response.data.status === 100) {
                            window.location.reload();
                        }
                    })
                    .catch(e => {

                        setButtonSpinner(false);

                        if (e.response.data.data.hasOwnProperty("password")) {
                            SweetAlert.fire({
                                toast: true,
                                icon: 'error',
                                title: e.response.data.data.password[0],
                                animation: false,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                    toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                }
                            });
                        } else {

                            SweetAlert.fire({
                                toast: true,
                                icon: 'error',
                                title: e.response.data.data.email[0],
                                animation: false,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                    toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                }
                            });
                        }
                    });

            }
        })




    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Testing Profile
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Add Testing Profile</div>
                        <div className="font14 fw500 color485">For Debug & Testing Purpose</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Your full name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Mobile number</label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">+91</span>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        {...register("mobileNumber", {
                                            required: "Mobile number is required",
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: "Enter a valid mobile number"
                                            }
                                        })}
                                        placeholder="Mobile Number"
                                        autoComplete="off"
                                        onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
                                    />
                                </div>
                                {errors.mobileNumber && <span className='redtext'>{errors.mobileNumber.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    {...register("password", { required: "Password is required" })}
                                    placeholder="Enter password"
                                    autoComplete="new-password"
                                />
                                {errors.password && <span className='redtext'>{errors.password.message}</span>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    {...register("excludeLms")}
                                    checked
                                    disabled
                                />
                                <label>Exclude LMS</label>
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}</button>

                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import SideImg from '../../img/BasicProfile.png';
import axioInstance from '../axiosInstance';
import { calculate_age } from '../global';
import AddChild from './basicDetails/AddChild';
import EditChild from './basicDetails/EditChild';

export default function BasicDetailsScreen2({ setSubstep, substep, nextsubStep, StepChange, steps, nextStep, prevsubStep, childCount, lifeStage }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [familyDetails, setFamilyDetails] = useState(
    {
      spouseName: '', spouseAge: '', spouseId: '',
      fatherName: '', fatherAge: '', fatherId: '',
      motherName: '', motherAge: '', motherId: ''
    }
  )

  const [kidsDetails, setKidsDetails] = useState([]);
  const [action, setAction] = useState('add');
  const [buttonSpinner, setButtonSpinner] = useState(false);

  useEffect(() => {

    const profile_id = sessionStorage.getItem("profile_id");

    axioInstance.post('profile/summary', { profile_id }).then(
      response => {
        if (response.data.status === 100) {

          const res = response.data;
          setKidsDetails(res.kids_det);
          setAction(res.child_cnt >= 1 ? 'edit' : 'add');

          const updatedFamilyDetails = {};

          res.prof_det.forEach(element => {
            const { relation, first_name, last_name, dob, profile_details_id } = element;
            const fullName = `${first_name} ${last_name}`;
            const age = calculate_age(dob);

            if (relation === 'spouse') {
              updatedFamilyDetails.spouseName = fullName;
              updatedFamilyDetails.spouseAge = age;
              updatedFamilyDetails.spouseId = profile_details_id;
            } else if (relation === 'mother') {
              updatedFamilyDetails.motherName = fullName;
              updatedFamilyDetails.motherAge = age;
              updatedFamilyDetails.motherId = profile_details_id;
            } else if (relation === 'father') {
              updatedFamilyDetails.fatherName = fullName;
              updatedFamilyDetails.fatherAge = age;
              updatedFamilyDetails.fatherId = profile_details_id;
            }
          });

          setFamilyDetails(prevState => ({ ...prevState, ...updatedFamilyDetails }));

        } else {

        }
      }
    )
  }, []);


  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(familyDetails);
    // eslint-disable-next-line
  }, [familyDetails, reset]);


  const onSubmit = async (data) => {
    setButtonSpinner(true);
    sessionStorage.setItem("familyData", JSON.stringify(data));
    
    const postData = {
      ...data,
      father_prof_det_id: familyDetails.fatherId,
      mother_prof_det_id: familyDetails.motherId,
      spouse_prof_det_id: familyDetails.spouseId,
      profile_id: sessionStorage.getItem('profile_id'),
      admin_id: sessionStorage.getItem('admin_id'),
      step: steps + 1,
    };

    try {
      // First API call to add family professional details
      const response = await axioInstance.post('profile/add-family-details', postData);

      if (response.data.status === 100) {

        setButtonSpinner(false);
        StepChange(nextStep(steps));
      } else {
        setButtonSpinner(false);
        throw new Error('Failed to add family details');
      }
    } catch (error) {
      setButtonSpinner(false);

      // Handle errors gracefully with SweetAlert
      SweetAlert.fire({
        toast: true,
        icon: 'error',
        title: error.message || 'Something went wrong. Retry again.',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer);
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
        },
      });
    }
  };


  return (
    <Fragment>
      <div className="mainwrapper">
        <div className='form_title'>
          <div className="mt30 font20 fw600">First things first!</div>
          <div className="font15 color495">Questions to know basic details</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
            </ul>

            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font18 fw600">Let's, talk about family</div>
                  <div className="font15 color495 ">Few details about your family members</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}
              <div className={"form_prnt pt25 pl35 pr35 pb35"}>
                <div className="custome_form">

                  {
                    (lifeStage === "marriedWithKids" || lifeStage === "married") &&
                    (<Fragment>

                      <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                          <label htmlFor="spouseName" className="font14 fw600">Your spouse name <span className="colorfa8">*</span></label>
                          <input type="text" className="form-control" id="spouseName" name="spouseName" autoComplete='off' {...register("spouseName", { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter Spouse Name" />
                          <span className="text-danger"> {errors.spouseName && errors.spouseName.message}</span>
                        </div>
                        <div className="form-group col-md-6 pl20">
                          <label htmlFor="spouseAge" className="font14 fw600">Age <span className="colorfa8">*</span></label>
                          <input type="text" className="form-control" id="spouseAge" name="spouseAge" autoComplete='off' {...register("spouseAge", { required: 'Age is required', min: { value: 18, message: 'Min age is 18' }, max: { value: 90, message: 'Max age is 90' } })} placeholder="Enter Spouse Age" />
                          <span className="text-danger"> {errors.spouseAge && errors.spouseAge.message}</span>
                        </div>
                      </div>


                    </Fragment>)
                  }


                  {
                    (childCount > 0 && action === "edit") && (
                      <EditChild
                        kidsDetails={kidsDetails}
                        register={register}
                        errors={errors} />
                    )
                  }
                  {
                    ((lifeStage === "marriedWithKids" || lifeStage === "married" || lifeStage === "separatedWithKids") && action === "add") && (
                      <AddChild
                        childCount={childCount}
                        register={register}
                        errors={errors}
                      />
                    )
                  }

                </div>


                {
                  (lifeStage === "single" || lifeStage === "married") && childCount === 0 ? <></> : <div className="formdevider mb20 mt20" />
                }



                <div className="custome_form">

                  <div className="form-row">
                    <div className="form-group col-md-6 pr20">
                      <label htmlFor="fatherName" className="font14 fw600">Your father's name </label>
                      <input type="text" className="form-control" id="fatherName" name="fatherName" autoComplete='off' {...register("fatherName")} placeholder="Enter full name" />
                    </div>
                    <div className="form-group col-md-6 pl20">
                      <label htmlFor="fatherAge" className="font14 fw600">Age</label>
                      <input type="text" className="form-control" id="fatherAge" name="fatherAge" autoComplete='off' {...register("fatherAge")} placeholder="Enter Father Age" />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 pr20">
                      <label htmlFor="motherName" className="font14 fw600">Your mother's name</label>
                      <input type="text" className="form-control" id="motherName" name="motherName" autoComplete='off' {...register("motherName")} placeholder="Enter Mother Name" />
                    </div>
                    <div className="form-group col-md-6 pl20">
                      <label htmlFor="motherAge" className="font14 fw600">Age </label>
                      <input type="text" className="form-control" id="motherAge" name="motherAge" autoComplete='off' {...register("motherAge")} placeholder="Enter Mother Age" />
                    </div>
                  </div>
                </div>

              </div>
              <div className="saveBtn d-block d-sm-none">
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save and Continue"}  <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary"> Save </button>
              </div>

              {/* Form End */}
            </form>
          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>


              {"We need to mandatorily ask these questions because our mission is to plan not only for you, for your family."}
            </div>


            <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
          </div>
        </div>

      </div>
    </Fragment >
  )
}

import React, { useEffect } from "react"; 
import { Link } from "react-router-dom";

const SidebarPopupComponent = ({ children, title="Filter", trigger = false,  id="sidebar_1", setSidebarTrigger}) => {

 

  // eslint-disable-next-line
 // const [open, setOpen] = useState(trigger === true ? 'open-filter' : '')

  useEffect(()=>{
    //console.log("trigger ::::: ", trigger);
    if(trigger){
      
      // Add the class to the body when the component mounts
      document.body.classList.add("filter-opened"); 
      // Optional: Clean up the class when the component unmounts
      return () => {
        document.body.classList.remove("filter-opened");
      };
    }
   
  }, [trigger]);

  return ( 
    <div className={trigger ? 'toggle-sidebar open-filter ' : ' toggle-sidebar '} id={id}>
        <div className="sidebar-layout-filter">
            <div className="sidebar-header">
                <h5>{title}</h5>
                <Link className="sidebar-closes" onClick={()=> setSidebarTrigger(false)}><i className="fa-regular fa-circle-xmark"></i> </Link>
            </div>
            <div className="sidebar-body">
                {children}  
            </div>
        </div>
    </div> 
  )
};

export default SidebarPopupComponent;

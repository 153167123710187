import React, { useEffect } from "react";

const InvestmentInput = ({
    register,
    errors,
    namePrefix,
    currentValueLabel,
    periodicInvestmentLabel,
    maxZero,
    investmentData,
    setValue
}) => {

    useEffect(() => {
        if (investmentData?.frequency !== undefined) {

            setValue(`${namePrefix}CurrentVal`, investmentData?.current_value || "");
            setValue(`${namePrefix}ValueMoneyType`, investmentData?.currentValueDigits || "100000");
            setValue(`${namePrefix}Invested`, investmentData?.amount || "");
            setValue(`${namePrefix}InvestedMoneyType`, investmentData?.InvestedDigits || "1000");
            setValue(`${namePrefix}Frequency`, investmentData?.frequency === 0 ? 0 : (investmentData?.frequency || 12));
            setValue(`${namePrefix}InvestId`, investmentData?.invest_id || "");

        }
    }, [investmentData, setValue, namePrefix]);

    return (
        <div className="form-row mt20">
            {/* Current Value Field */}
            <div className="col-md-6 pr20">
                <label className="font14 fw600">
                    {currentValueLabel} <span className="colorfa8">*</span>
                </label>
                <div className="input-group selectarrow">
                    <input
                        type="text"
                        className="form-control color212 font14 fw500"
                        name={`${namePrefix}CurrentVal`}
                        {...register(`${namePrefix}CurrentVal`, { required: "Required. Enter 0 to skip" })}
                        maxLength={maxZero}
                        minLength={1}
                    />
                    <select
                        className="custom-select"
                        name={`${namePrefix}ValueMoneyType`}
                        {...register(`${namePrefix}ValueMoneyType`)}
                    >
                        <option value="100000">Lakhs</option>
                        <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                </div>
                <span className="text-danger">{errors[`${namePrefix}CurrentVal`] && errors[`${namePrefix}CurrentVal`].message}</span>
            </div>

            {/* Periodic Investment Field */}
            <div className="col-md-6 pl20">
                <label className="font14 fw600">
                    {periodicInvestmentLabel} <span className="colorfa8">*</span>
                </label>
                <div className="input-group selectarrow">
                    <input
                        type="text"
                        className="form-control color212 font14 fw500"
                        name={`${namePrefix}Invested`}
                        {...register(`${namePrefix}Invested`, { required: "Required. Enter 0 to skip" })}
                        maxLength={maxZero}
                        minLength={1}
                    />

                    <select
                        className="custom-select font14"
                        name={`${namePrefix}InvestedMoneyType`}
                        {...register(`${namePrefix}InvestedMoneyType`)}
                    >
                        <option value="1000">Thous.</option>
                        <option value="100000">Lakhs</option>
                        <option value="10000000">Crore</option>
                    </select>
                    <select
                        className="custom-select"
                        name={`${namePrefix}Frequency`}
                        {...register(`${namePrefix}Frequency`)}
                    >
                        <option value="12">Monthly</option>
                        <option value="4">Quarterly</option>
                        <option value="2">Half Yearly</option>
                        <option value="1">Yearly</option>
                        <option value="0">Lumpsum</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                </div>
                <span className="text-danger">{errors[`${namePrefix}Invested`] && errors[`${namePrefix}Invested`].message}</span>
            </div>
            <input type="hidden" {...register(`${namePrefix}InvestId`)} />
        </div>
        
    );
};

export default InvestmentInput;

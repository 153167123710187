import React from "react";

const TableContainer = ({ children }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card-table">
          <div className="card-body">
            <div className="table-responsive">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableContainer;

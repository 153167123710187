import React, { Fragment } from 'react'
import { calculate_age } from '../../global';

export default function EditChild({ kidsDetails, register, errors }) {
    return (
        <>
            {Object.keys(kidsDetails).map((kids, i) => {
                const kid = kidsDetails[kids];

                return (
                    <Fragment key={i}>
                        {kid.relation === "child" && (
                            <div className="form-row">
                                <div className="form-group col-md-6 pr20">
                                    <label htmlFor={'childName' + i} className="font14 fw600">
                                        Child {i + 1} <span className="colorfa8">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={'childName' + i}
                                        name={`childsArr[${i}].name`}
                                        autoComplete="off"
                                        {...register(`childsArr.${i}.name`, {
                                            required: 'Name is required',
                                            minLength: { value: 3, message: 'Name is too short' },
                                            maxLength: { value: 50, message: 'Name is too long' },
                                        })}
                                        placeholder="Enter Child Name"
                                        defaultValue={kid.full_name} // Prepopulate with existing value
                                    />
                                    <span className="text-danger">
                                        {errors.childsArr?.[i]?.name?.message}
                                    </span>
                                </div>

                                <div className="form-group col-md-6 pl20">
                                    <label htmlFor={'childAge' + i} className="font14 fw600">
                                        Age <span className="colorfa8">*</span>
                                    </label>
                                    <div className="input-group selectarrow">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={'childAge' + i}
                                            name={`childsArr[${i}].age`}
                                            autoComplete="off"
                                            {...register(`childsArr.${i}.age`, {
                                                required: 'Age is required',
                                                min: { value: 0, message: 'Min value is 1' },
                                            })}
                                            placeholder="Enter Child Age"
                                            defaultValue={calculate_age(kid.dob)} // Prepopulate with existing value
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <select
                                            className="custom-select font14"
                                            id="inputGroupSelect01"
                                            name={`childsArr[${i}].ageType`}
                                            {...register(`childsArr.${i}.ageType`)}
                                        >
                                            <option value="years">Year</option>
                                            <option value="months">Months</option>
                                            <option value="days">Days</option>
                                        </select>
                                    </div>
                                    <span className="text-danger">
                                        {errors.childsArr?.[i]?.age?.message}
                                    </span>
                                </div>

                                <input
                                    type="hidden"
                                    className="form-control"
                                    id={'childProfDetId' + i}
                                    name={`childsArr[${i}].child_prof_det_id`}
                                    autoComplete="off"
                                    {...register(`childsArr.${i}.child_prof_det_id`)}
                                    defaultValue={kid.profile_details_id} // Prepopulate with existing value
                                />
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </>
    );
}


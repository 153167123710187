import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axioInstance from '../components/axiosInstance';
import { Encryption } from '../components/global';
import SweetAlert from 'sweetalert2';

export default function AccountManagerMap({ accountManagerClose, profile_id }) {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [serManagers, setSerManagers] = useState([]);
    const [relManagers, setRelManagers] = useState([]);
    const [profileName, setProfileName] = useState("");

    useEffect(() => {
        axioInstance.post(`admin/get-rm-ser-managers`, { profile_id: Encryption(profile_id) })
            .then(response => {
                if (response.data.status === 100) {
                    setSerManagers(response.data.ser_manager || []);
                    setRelManagers(response.data.rel_manager || []);
                    setProfileName(response.data.profile_name || "");

                    // Set default values after fetching
                    setValue("rel_manager", response.data.get_selected_rel_manager || "");
                    setValue("ser_manager", response.data.get_selected_ser_manager || "");
                }
            })
            .catch(error => console.error("Error fetching data:", error));
    }, [profile_id, setValue]);

    const onSubmit = (postData) => {
        setButtonSpinner(true);
        const Data = {
            profile_id: Encryption(profile_id),
            rel_manager: postData.rel_manager,
            ser_manager: postData.ser_manager
        };

        axioInstance.post(`admin/update-profile-manager`, Data)
            .then(response => {
                if (response.data.status === 100) {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Successfully Updated Profile Manager',
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    }).then(() => window.location.reload());
                } else {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Something went wrong, please try again later',
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    }).then(() => window.location.reload());
                }
            })
            .catch(() => {
                SweetAlert.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Something went wrong, please try again later',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                }).then(() => window.location.reload());
            });
    };

    return (
        <Fragment>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Update REL & SER Manager</div>
                                    </div>
                                </div>

                                {/* Profile Name Field */}
                                <div className="form-group col-md-12 pl20 mt25">
                                    <label>Name*</label>
                                    <input type='text' className="form-control" autoComplete='off' 
                                        {...register("profile_name")} readOnly value={profileName} />
                                </div>

                                {/* REL Manager Dropdown */}
                                <div className="form-group col-md-12 pl20 mt25">
                                    <label>REL Manager*</label>
                                    <select className="form-control" autoComplete='off'
                                        {...register("rel_manager", { required: 'Rel Manager is required' })}
                                        value={watch("rel_manager") || ""}
                                        onChange={(e) => setValue("rel_manager", e.target.value)}
                                    >
                                        <option disabled value="">Select REL Manager</option>
                                        {relManagers.map(manager => (
                                            <option key={manager.emp_id} value={manager.short_name}>
                                                {manager.emp_name}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.rel_manager && errors.rel_manager.message}</span>
                                </div>

                                {/* SER Manager Dropdown */}
                                <div className="form-group col-md-12 pl20 mt25">
                                    <label>SER Manager*</label>
                                    <select className="form-control" autoComplete='off'
                                        {...register("ser_manager", { required: 'Ser Manager is required' })}
                                        value={watch("ser_manager") || ""}
                                        onChange={(e) => setValue("ser_manager", e.target.value)}
                                    >
                                        <option disabled value="">Select SER Manager</option>
                                        {serManagers.map(manager => (
                                            <option key={manager.emp_id} value={manager.short_name}>
                                                {manager.emp_name}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.ser_manager && errors.ser_manager.message}</span>
                                </div>

                                {/* Submit and Cancel Buttons */}
                                <div className="form_prnt pt25 pl35 pr35 pb35">
                                    <div className="updateStatusBtn backsaveBtn">
                                        <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary">
                                            {buttonSpinner ? <><i className="fa fa-spinner fa-spin"></i> Updating</> : "Update"}
                                        </button>
                                        <button type="button" className="btn btn-primary" onClick={() => accountManagerClose(false)}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
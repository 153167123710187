import React, { Fragment, useEffect, useState } from 'react'
import axioInstance from '../axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
//import { Encryption } from './global'

export default function AdminMarkActiveInactive({ setActive, setAction, adminId }) {

    const Back = () => {
        setActive(false);
        setAction('');
    }

    const [adminData, setAdminData] = useState([]);
    const [status, setStatus] = useState(""); 
    const [profileMapped, setProfileMapped] = useState(0);


    useEffect(() => {
        axioInstance.get(`admin-users/`+adminId).then(
            (response, data) => { 
                let self_admin = response.data.data.find(values => values.admin_id === adminId);
                setAdminData(self_admin); 
                console.log("users_admin_status", self_admin.users_admin_status, self_admin)
                setStatus(self_admin.users_admin_status); 
                setProfileMapped(self_admin.profile_mapped_count);
            }
        );
    }, [adminId])

    // eslint-disable-next-line
    const { register, handleSubmit, setError, formState: { errors }, setValue } = useForm();

    useEffect(() => {
        if (adminData) {
            setValue("status", adminData.users_admin_status); 
            setValue("admin_id", adminData.admin_id) 
        }
    }, [adminData, setValue]);

    const onSubmit = formData => { 
        axioInstance.put(`/admin-users/update-managers-status/${formData.admin_id}`, {
            status: formData.status,
            updated_by: formData.updated_by
        }).then(function (response) {
            Back();
            SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Admin User Status Updated Successfully.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {
               window.location.reload();
            });

        }).catch(function (error) {
            // Handle error
            SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Unable to update Admin User, Something went wrong.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {
                //nothing
            });
        });

    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Mark Admin Active/Inactive
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Update Admin User Status</div>
                        <div className="font14 fw500 color485">Finnovate Users</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}> 
                            <input type='hidden' name='admin_id' value={adminId} {...register("admin_id", { required: "admin_id is required" })} /> 
                            <div className="form-group">
                                <label>Admin Name </label>: <b>{adminData.admin_name}  </b><br />
                                <label>Profile Mapped </label>:  <b>{ profileMapped>0 ? 'Yes' : 'No' }</b>
                            </div>
                            <div className="form-group">
                              <label>Change Active/Inactive </label>
                              <select type="text" className="form-control" id="status" defaultValue={status} 
                              {...register("status", { required: "Status is required" })}>
                                  <option value="">ALL</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option> 
                              </select>
                              {errors.status && <span className='redtext'>{errors.status.message}</span>}
                          </div>  
                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                       

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

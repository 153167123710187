import React, { useState } from 'react'
import { Link,  NavLink,  useLocation,  useNavigate } from 'react-router-dom'
import AddTestProfileSidebar from '../AddTestProfileSidebar';
import AddDirectProfileSidebar from '../AddDirectProfileSidebar'; 

export default function AdminDashboardSidebarMenuNew() {
  
/* eslint-disable no-unused-vars */
  let navigate = useNavigate();
  const [isActive1, setActive] = useState(false);
  const [action, setAction] = useState();


    const addProfile = () => {

      console.log('hostname', window.location);
  
      const url = window.location.origin + "/sign-in?q=2&ref_by=" + localStorage.getItem('shortName') + "&s=" + localStorage.getItem('admin_id');
  
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  
    }


    const location = useLocation();

    // Check if any child routes match the current location
    const isActive2 = [
      "/admin-dashboard",
      "/guest-users-dashboard",
      "/plan-login-logs-dashboard",
      "/profile-event-logs-dashboard",
      "/manage-admin-users",
    ].includes(location.pathname);

    const isActive3 = [
      "/plan-profiles-timeline",
      "/plan-profiles-status",
      "/plan-summary-report-drilldown-month",
    ].includes(location.pathname);


    
    return (
        <>
    <div className="sidebar" id="sidebar">
      
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title"><span>Main</span></li>
            <li className="submenu">
              <Link to="#" className={isActive2 ? "active" : ""}>
                <i className="fe fe-home"></i> <span>Admin</span>{" "}
                <span className="menu-arrow"></span>
              </Link>
              <ul>  
                <li><NavLink  to="/admin-dashboard" className={({ isActive }) => (isActive ? 'active' : '')}  >Admin Dashboard</NavLink></li>
                <li><Link onClick={addProfile}>Generate Sign In Link</Link></li>
                <li><Link onClick={() => { setActive(true); setAction('addTestProfile') }}>Add Testing profile</Link></li>
                <li><Link onClick={() => { setActive(true); setAction('addDirectProfile') }} >Add Guest User</Link></li>  
                {
              (localStorage.getItem('admin_id') === '1' || localStorage.getItem('admin_id') === '27' || localStorage.getItem('admin_id') === '7' || localStorage.getItem('admin_id') === '61') && (
              <>  
                <li><a  href="/guest-users-dashboard" className={({ isActive }) => (isActive ? 'active' : '')}  >Guest Users</a></li>
                <li><NavLink  to="/plan-login-logs-dashboard" className={({ isActive }) => (isActive ? 'active' : '')}  >Plan Login Logs</NavLink></li>  
                <li><NavLink  to="/manage-admin-users" className={({ isActive }) => (isActive ? 'active' : '')}  >Manage Admin Users</NavLink></li>  
                
              </>)
              }
                
                <li><NavLink to='/profile-event-logs-dashboard'  className={({ isActive }) => (isActive ? 'active' : '')}  >Plan Events Logs</NavLink></li>
              </ul>
            </li>

            {
              localStorage.getItem('admin_id') === '1' && (
            <li className="submenu">
              <Link href="#" className={isActive3 ? "active" : ""}>
                <i className="fe fe-file"></i> 
                <span>Plan Reports</span>
                <span className="menu-arrow"></span>
              </Link>
              <ul>
                <li><NavLink  to="/plan-profiles-timeline" className={({ isActive }) => (isActive ? 'active' : '')}  >Profile Timeline</NavLink></li>
                <li><NavLink to='/plan-profiles-status' className={({ isActive }) => (isActive ? 'active' : '')}>Profile Plan Status</NavLink></li> 
                <li><NavLink to='/plan-summary-report-drilldown-month' className={({ isActive }) => (isActive ? 'active' : '')}>Summary Month Wise</NavLink></li> 
              </ul>
            </li>)
          }


          
          </ul>

         {/*  <ul>
            <li className="menu-title"><span>Plan Reports</span></li>
            <li>
              <a href="customers.html">
                <i className="fe fe-users"></i> <span>Customers</span>
              </a>
            </li>
            <li>
              <a href="customer-details.html">
                <i className="fe fe-file"></i> <span>Customer Details</span>
              </a>
            </li>
            <li>
              <a href="vendors.html">
                <i className="fe fe-user"></i> <span>Vendors</span>
              </a>
            </li>
          </ul>

          <ul>
            <li className="menu-title"><span>Settings</span></li>
            <li>
              <a href="settings.html">
                <i className="fe fe-settings"></i> <span>Settings</span>
              </a>
            </li>
            <li>
              <a href="login.html">
                <i className="fe fe-power"></i> <span>Logout</span>
              </a>
            </li>
          </ul> */}

        </div>
      </div>
    </div>

      {
        (isActive1 === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

      {
        (isActive1 === true && action === 'addDirectProfile') && (<AddDirectProfileSidebar setActive={setActive} setAction={setAction} />)
      } 
  </>
  )
}
import React, { useState, useEffect } from 'react'
import axioInstance from '../components/axiosInstance'
import FinnLoader from '../components/FinnLoader'
import FinnModal from '../components/FinnModal'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { apiUrl, Encryption } from '../components/global';

export default function RiskProfileView() {

    const [loading, setLoading] = useState(true);
    const [riskProfileData, setRiskProfileData] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    // eslint-disable-next-line
    const [errorMessage, setErrorMessage] = useState("");
    const [errorTitle, setErrorTitle] = useState("Something went wrong");
    const [errorFooter, setErrorFooter] = useState();
    const [showButton, setShowButton] = useState(true);

    const [riskQna, setRiskQna] = useState([]);
    // eslint-disable-next-line
    const [fullname, setFullname] = useState();
    const [activeId, setActiveId] = useState();
    const [countdown, setCountdown] = useState(3); // Start countdown from 3
    const [email, setEmail] = useState();
    useEffect(() => {
        document.body.classList.add('white_bg_fp_report');

        return function cleanup() {
            document.body.classList.remove('white_bg_fp_report');
        };
    }, []);

    const navigate = useNavigate();

    useEffect(() => {


        const search = window.location.search;
        const params = new URLSearchParams(search);
        //const id = params.get('id');
        const profile_id = params.get('pid');



        axioInstance.post(`admin/validate-key-id`, { profile_id: profile_id }).then(
            (response, data) => {

                if (response.data.status === 101) {
                    window.location.href = '/sign-in';
                } else {


                    const token = response.data.token;

                    axios
                        .post(`${apiUrl}profile/plan-fpreport`, { profile_id: Encryption(response.data.key), req: 'r' }, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Authorization": token ? `Bearer ${token}` : "",
                                "Content-Type": "application/json",
                            }
                        })
                        .then((response) => {

                            setEmail(response.data.email);
                            if (response.data.status === 100) {

                                const filteredRiskProfileData = response.data.riskProfileData.filter(
                                    (item) => item.add_source === "2" && item.risk_type >= 1
                                );

                                if (filteredRiskProfileData.length > 0) {

                                    setRiskProfileData(filteredRiskProfileData || []);

                                    const quest = filteredRiskProfileData[0]?.q_n_a ? JSON.parse(filteredRiskProfileData[0].q_n_a) : {};
                                    setRiskQna(quest?.question || []);
                                    setActiveId(filteredRiskProfileData[0].risk_id);

                                } else {

                                    setErrorTitle("Risk Profile");
                                    setErrorMessage(
                                        <div>
                                            <p><strong className='text-danger font18'>Your Investment Risk Profile is not yet created.</strong></p>

                                        </div>
                                    );
                                    setErrorFooter(<div>
                                        <p>
                                            <a href={`risk-profile-link?email=${email}`}>Create Here</a>
                                        </p>
                                    </div>);
                                    setShowButton(false);
                                    setShowErrorModal(true);

                                }
                            }

                        })
                        .catch((error) => {

                            if (error.message === "Token has expired" || error.message === "Request failed with status code 401") {

                                setErrorTitle("Session Expired!");
                                setErrorMessage(
                                    <div>
                                        <strong className='text-danger'>Your session has expired. Please log in again.</strong>
                                        <br /><small>Redirecting to login page in <span className="countdown">{countdown}</span> sec...</small>
                                    </div>
                                );
                                setErrorFooter('');
                                setShowButton(false);
                                setShowErrorModal(true);

                                // Start countdown
                                let interval = setInterval(() => {
                                    setCountdown((prev) => {
                                        if (prev === 1) {
                                            clearInterval(interval);

                                            sessionStorage.removeItem("token");
                                            sessionStorage.removeItem("profile_id");
                                            sessionStorage.removeItem("full_name");
                                            navigate(sessionStorage.getItem("admin_id") ? "/ulogin" : "/sign-in"); // Redirect to login
                                            sessionStorage.clear();

                                        }
                                        return prev - 1;
                                    });
                                }, 1000);

                            } else {
                                setErrorTitle("Risk Profile");
                                setErrorMessage(
                                    <div>
                                        <p><strong className='text-danger font18'>Your Investment Risk Profile is not yet created.</strong></p>

                                    </div>
                                );
                                setErrorFooter(<div>
                                    <p>
                                        <a href={`risk-profile-link?email=${email}`}>Create Here</a>
                                    </p>
                                </div>);
                                setShowButton(false);
                                setShowErrorModal(false);

                            }

                        }).finally(() => {
                            setLoading(false);
                        });
                }
            }
        );





    }, [countdown, navigate, email]);

    const displayQna = (q_n_a, risk_id) => {

        setLoading(true);

        const quest = q_n_a ? JSON.parse(q_n_a) : {};
        setRiskQna(quest?.question || []);
        setActiveId(risk_id);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    return (
        <>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    {loading && <FinnLoader />}
                    <div className="planSection_wpr row">
                        <div className="col-3 leftNav pt55 font14 fw500 color7B8">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link" id="RiskProfileSummary-tab" data-toggle="pill" href="#RiskProfileSummary" role="tab" aria-controls="RiskProfileSummary" aria-selected="false"><span className="goalSummaryIcon mr10" /> Risk Summary</a>
                            </div>
                        </div>
                        <div className="col-9 righttabContent pl90 pt25">
                            <div id="RiskProfileSummary">
                                {/*<RiskProfileSummary summaryData={summaryData} lifeCycleData={lifeCycleData} planStatusHistoryData={planStatusHistoryData} riskProfileData={riskProfileData} error={error} setError={setError} finnfitData={finnfitData} /> */}
                                <div className="tabContent_title font12 fw500 color7B8 pb15">
                                    <span className="color263">{fullname} Risk Summary</span>
                                </div>
                                <div className="font22 fw700 color0C4 pt30">Risk Summary</div>
                                {
                                    riskProfileData.length === 0 ? <p className='font14'>No Data</p> :
                                        (<>
                                            {riskProfileData.map((history, key) => (
                                                <div className="loanBox_outer mt25 p25" key={key}>
                                                    <div className="loandtl">
                                                        <div className="font13 fw500 color6d7">Risk Description</div>
                                                        <div className="font14 fw500 color182">{history.risk_description}</div>
                                                    </div>
                                                    <div className="loandtl">
                                                        <div className="font13 fw500 color6d7">Date</div>
                                                        <div className="font14 fw500 color182">{new Date(history.created).toLocaleDateString('en-GB')}</div>
                                                    </div>
                                                    <div className="loandtl">
                                                        <div className="font13 fw500 color6d7">PDF</div>
                                                        <div className="font14 fw500 color182">
                                                            {history.ref_doc && (
                                                                <a
                                                                    href={`https://planapi.finnovate.in/uploads/riskpdf/${history.ref_doc}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="color111"
                                                                >
                                                                    <i className="fas fa-file-pdf fa-2x"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="loandtl">
                                                        <div className="font13 fw500 color6d7"></div>
                                                        <div className="font14 fw500 color182">

                                                            {
                                                                history.risk_id === activeId ? "Current" : (
                                                                    <>
                                                                        <i className="fas fa-eye fa-1x pointer" onClick={() => displayQna(history.q_n_a, history.risk_id)}></i>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}

                                        </>)
                                }

                                <div className="lineDevider mt40 mb40" />

                                <div className="font22 fw700 color0C4">Risk Profile QNA</div>

                                {riskQna.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>No Data</td>
                                    </tr>
                                ) : (riskQna.map((item, index) => {
                                    return (
                                        <>

                                            <div key={index}>
                                                <label className="font16 fw600 color6d7 mt15">{item.label}</label>
                                                <div className="row">
                                                    {item.answers.map((company, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="radio_btn">
                                                                        <label>
                                                                            <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} checked={company.ans_id === item.ans_value ? true : false} className='resetofgoal_box mr8' />
                                                                            <span className='font13 fw500 color485'>{company.name}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                    )}

                                                </div>

                                            </div>

                                            <div className='mt15' />

                                        </>
                                    );
                                }))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FinnModal
                show={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                title={errorTitle}
                message={errorMessage}
                footer={errorFooter}
                isClosable={false} // Set to false if you don't want it to be closable
                isAction={showButton}
                primaryButtonText="Refresh"
                secondaryButtonText="Back to Dashboard"
                onPrimaryClick={() => {
                    setShowErrorModal(false);
                    window.location.reload();
                }}
                onSecondaryClick={() => window.location.href = 'dashboard'}
            />
        </>
    )
}

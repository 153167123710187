import React from "react";

function Loader() {
  return (
    <div style={styles.loaderContainer}>
      <img
        src="images/loader.gif"
        alt="Loading..."
        style={styles.loaderImage}
      />
      <br />
      <span style={styles.loadingText}>Loading...</span>
    </div>
  );
}

const styles = {
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "95vh",
    backgroundColor: "#f9f9f9"
  },
  loaderImage: {
    width: "100px",
    height: "100px",
  },
  loadingText: { 
    fontSize: "14px",
    color: "#555",
  },
};

export default Loader;

import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import axioInstance from '../axiosInstance';
import formatAmount from 'indian-currency-formatter';
import { Encryption, Decryption, allowOnlyNumbers, frequencyOptions, QuoteSection } from '../global';
import SideImg from '../../img/BasicProfile.png';

export default function ProtectionScreen1({ setSubstep, substep, nextsubStep, lifeStage, clientName }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [terms, setTerms] = useState(null);

  const [selfFrequency, setSelfFrequency] = useState("1");
  const [spouseFrequency, setSpouseFrequency] = useState("1");

  useEffect(() => {

    axioInstance.post(`profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
      const res = response.data[0];
      setTerms({ selftermInsurance: res.selftermInsurance, selfsumAssured: res.selfsumAssured > 0 ? res.selfsumAssured : '', selfMoneyType: res.selfMoneyType, selfpremiumAmount: res.selfpremiumAmount > 0 ? formatAmount(res.selfpremiumAmount) : '', spousetermInsurance: res.spousetermInsurance, spousesumAssured: res.spousesumAssured > 0 ? res.spousesumAssured : '', spouseMoneyType: res.spouseMoneyType, spousepremiumAmount: res.spousepremiumAmount > 0 ? formatAmount(res.spousepremiumAmount) : '', selfInvestId: res.selfInvestId, spouseInvestId: res.spouseInvestId, spouseFrequency: res.spouseFrequency, selfFrequency: res.selfFrequency });

      setSelfFrequency(res.selfFrequency);
      setSpouseFrequency(res.spouseFrequency);
    });



    axioInstance.post(`profile-details/getfamilybyrelation`, { profile_id: sessionStorage.getItem('profile_id'), relation: 'onlyspouse' }).then(function (response, data) {

      if (response.data.length > 0) {
        sessionStorage.setItem('spouse_prof_det_id', Encryption(response.data[0].profile_details_id));
      }


    });

  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(terms);
    // eslint-disable-next-line
  }, [terms]);

  const [selfsumAssured, setSelfsumAssured] = useState();
  const [selfpremiumAmount, setSelfpremiumAmount] = useState();
  const [spousepremiumAmount, setSpousepremiumAmount] = useState();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'selfpremiumAmount') {
      setSelfpremiumAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'spousepremiumAmount') {
      setSpousepremiumAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === "selfsumAssured") {
      setSelfsumAssured(e.target.value);
    }

  };

  const getInvestmentPostData = (type, data, sessionData) => ({
    "prop_id": sessionData.profile_id,
    "profile_id": sessionData.profile_id,
    "profile_details_id": type === "self" ? sessionData.profile_details_id : sessionData.spouse_prof_det_id,
    "type": 4,
    "sub_type": 1,
    "owner": sessionData.profile_id,
    "instr_name": type === "self" ? "Self Term" : "Spouse Term",
    "invest_type": 0,
    "invest_amount": type === "self" ? data.selfpremiumAmount.replace(/,/g, "") : data.spousepremiumAmount.replace(/,/g, ""),
    "amount": type === "self" ? data.selfpremiumAmount.replace(/,/g, "") : data.spousepremiumAmount.replace(/,/g, ""),
    "cost_amount": type === "self" ? data.selfMoneyType * data.selfsumAssured : data.spouseMoneyType * data.spousesumAssured,
    "insured_value": type === "self" ? data.selfMoneyType * data.selfsumAssured : data.spouseMoneyType * data.spousesumAssured,
    "current_value": type === "self" ? data.selfMoneyType * data.selfsumAssured : data.spouseMoneyType * data.spousesumAssured,
    "moneyDigits": type === "self" ? data.selfMoneyType : data.spouseMoneyType,
    "frequency": type === "self" ? data.selfFrequency : data.spouseFrequency,
    "ppt": 1,
    "policy_term": 1,
    "months": "Y",
    "rider_id": [Decryption(type === "self" ? sessionData.profile_details_id : sessionData.spouse_prof_det_id)],
    "invest_id": type === "self" ? data.selfInvestId : data.spouseInvestId,
    "admin_id": sessionData.admin_id,
  });

  const handleInvestment = async (data, sessionData, type) => {
    const postData = getInvestmentPostData(type, data, sessionData);

    try {
      await axioInstance.post(`investment/add-investment`, postData);
      return true;
    } catch (error) {
      handleError();
      return false;
    }
  };

  const handleError = () => {
    SweetAlert.fire({
      toast: true,
      icon: 'error',
      title: 'Failed. Something went wrong. Retry again.',
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', SweetAlert.stopTimer);
        toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
      }
    });
  };


  const onSubmit = async (data) => {

    setButtonSpinner(true);

    const sessionData = {
      profile_id: sessionStorage.getItem('profile_id'),
      profile_details_id: sessionStorage.getItem('profile_details_id'),
      spouse_prof_det_id: sessionStorage.getItem('spouse_prof_det_id'),
      admin_id: sessionStorage.getItem("admin_id"),
    };

    var selfSuccess = '';
    if (data.selftermInsurance === "yes") {

      // Handle self-term insurance
      selfSuccess = await handleInvestment(data, sessionData, "self");

    }

    if (data.spousetermInsurance === "yes") {
      // Handle spouse-term insurance if applicable
      const spouseSuccess = await handleInvestment(data, sessionData, "spouse");
      if (spouseSuccess) {
        finalize(data);
      }
    } else if (selfSuccess) {
      finalize(data);
    }else{
      finalize(data);
    }

  }

  const finalize = (data) => {
    setButtonSpinner(false);
    sessionStorage.setItem("protectionData", JSON.stringify(data));
    setSubstep(nextsubStep(substep, 2));
  };

  const [selfInput, setSelfInput] = useState(true);
  const [spousefInput, setSpouseInput] = useState(true);



  const handleInsuranceChange = (type, value) => {
    if (type === "self") {
      setSelfInput(value === "yes");
    } else {
      setSpouseInput(value === "yes");
    }
  };




  return (
    <Fragment>

      <div className="mainwrapper">

        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Protection</div>
          <div className="font15 color495">Few questions to understand your family's protection</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li className="act">1</li>
              <li>2</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Are you protected enough?</div>
                  <div className="font15 color495 ">Give us a sneak of your term life insurance details</div>
                </div>
                <div>

                  <button type={buttonSpinner ? "button" : "submit"} className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <input type="hidden" name="selfInvestId" {...register("selfInvestId")} value="" />
                <input type="hidden" name="spouseInvestId" {...register("spouseInvestId")} value="" />

                <div className="form-row mb-2">
                  <div>
                    <div className="color212 font14 fw600">Do you have Term Life Insurance? <span className="colorfa8">*</span>
                    </div>
                    <ul className="steps_tag font14 fw500 color182 mt15">
                      <li>
                        <label>
                          <input type="radio" name="selftermInsurance" {...register("selftermInsurance", { required: 'Term Insurance is required' })} value="yes" onClick={(e) => handleInsuranceChange("self", e.target.value)} checked={selfInput === true ? "checked" : ""} />
                          <span className="rdo_text_box">
                            Yes <i className="fa-solid fa-check FaIcon"></i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="selftermInsurance" {...register("selftermInsurance", { required: 'Term Insurance is required' })} value="no" onClick={(e) => handleInsuranceChange("self", e.target.value)} {...selfInput === false ? "checked" : ""} />
                          <span className="rdo_text_box">
                            No <i className="fa-solid fa-xmark FaIcon"></i>
                          </span>
                        </label>
                      </li>
                    </ul>
                    <span className="text-danger"> {errors.selftermInsurance && errors.selftermInsurance.message}</span>
                  </div>
                </div>

                {

                  selfInput === true && (<>
                    <div className="form-row">
                      <div className="col-md-6 pr20">
                        <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                        <div className="input-group selectarrow">
                          <input type="text" className="form-control font14 color212 fw500" name="selfsumAssured" aria-label="Text input with dropdown button" autoComplete='off' {...register("selfsumAssured", { required: 'Sum assured is required' })} defaultValue={selfsumAssured} onChange={handleChange} maxLength={3} minLength={1} onKeyDown={allowOnlyNumbers} />
                          <select className="custom-select" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                            <option value="100000" defaultValue={"100000"}>Lakhs</option>
                            <option value="10000000">Crore</option>
                          </select>
                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                        <span className="text-danger"> {errors.selfsumAssured && errors.selfsumAssured.message}</span>
                      </div>
                      <div className="col-md-6 pl20">
                        <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                        <div className="input-group selectarrow">
                          <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="selfpremiumAmount" autoComplete='off' {...register("selfpremiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={selfpremiumAmount} onKeyDown={allowOnlyNumbers} />
                          <select className="custom-select" id="inputGroupSelect01" name='selfFrequency' {...register("selfFrequency")} defaultValue={selfFrequency} >
                            {frequencyOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>

                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                        <span className="text-danger"> {errors.selfpremiumAmount && errors.selfpremiumAmount.message}</span>
                      </div>
                    </div>
                  </>)
                }

                {
                  (lifeStage === "separatedWithKids" || lifeStage === "single") ? <></> :
                    <>
                      <Fragment>
                        <div className="formdevider mt35 mb35" />

                        <div className="form-row">
                          <div>
                            <div className="color212 font14 fw600">Does your spouse have have Term Life Insurance?<span className="colorfa8">*</span>
                            </div>
                            <ul className="steps_tag font14 fw500 color182 mt15">
                              <li>
                                <label>
                                  <input type="radio" name="spousetermInsurance" {...register("spousetermInsurance", { required: 'Term Insurance is required' })} value="yes" onClick={(e) => handleInsuranceChange("spouse", e.target.value)} checked={spousefInput === true ? "checked" : ""} />
                                  <span className="rdo_text_box">
                                    Yes <i className="fa-solid fa-check FaIcon"></i>
                                  </span>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input type="radio" name="spousetermInsurance" {...register("spousetermInsurance", { required: 'Term Insurance is required' })} value="no" onClick={(e) => handleInsuranceChange("spouse", e.target.value)} {...spousefInput === false ? "checked" : ""} />
                                  <span className="rdo_text_box">
                                    No <i className="fa-solid fa-xmark FaIcon"></i>
                                  </span>
                                </label>
                              </li>
                            </ul>
                            <span className="text-danger"> {errors.spousetermInsurance && errors.spousetermInsurance.message}</span>
                          </div>
                        </div>

                        {

                          spousefInput === true && (<>
                            <div className="form-row">
                              <div className="col-md-6 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                                <div className="input-group selectarrow">
                                  <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="spousesumAssured" {...register("spousesumAssured", { required: 'Sum assured is required' })} maxLength={3} minLength={1} onKeyDown={allowOnlyNumbers} />
                                  <select className="custom-select" id="inputGroupSelect01" name='spouseMoneyType' {...register("spouseMoneyType")}>
                                    <option selected value="100000">Lakhs</option>
                                    <option value="10000000">Crore</option>
                                  </select>
                                  <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                                <span className="text-danger"> {errors.spousesumAssured && errors.spousesumAssured.message}</span>
                              </div>
                              <div className="col-md-6 pl20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                                <div className="input-group selectarrow">
                                  <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="spousepremiumAmount" {...register("spousepremiumAmount", { required: 'Sum assured is required' })} onChange={handleChange} value={spousepremiumAmount} onKeyDown={allowOnlyNumbers} />
                                  <select className="custom-select" id="inputGroupSelect01" name='spouseFrequency' {...register("spouseFrequency")} defaultValue={spouseFrequency}>
                                    {frequencyOptions.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                                <span className="text-danger"> {errors.spousepremiumAmount && errors.spousepremiumAmount.message}</span>
                              </div>
                            </div>
                          </>)
                        }


                      </Fragment>
                    </>
                }


              </div>

              <div className="saveBtn d-block d-sm-none">
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save and Continue"} <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>
            </form>
            {/* Form End */}

          </div>


          <QuoteSection
            text="We care about your insurance because this comes first in your financial journey."
            imageName={SideImg}
            altText="Protection"
          />

        </div>

      </div>
    </Fragment>
  )
}
import React, { Fragment, useEffect, useState } from 'react'

import Gold from '../investments/Gold'
import FixedDeposits from '../investments/FixedDeposits'
import MutualFund from '../investments/MutualFund'
import Nps from '../investments/Nps'
import PpfEpf from '../investments/PpfEpf'
import RealEstate from '../investments/RealEstate'
import Stocks from '../investments/Stocks'
import SideImg from '../../img/investment.png';
import { Modal, ModalBody } from 'react-bootstrap';

export default function InvestmentScreen2({ setSubstep, prevsubStep, substep, StepChange, steps, nextStep, lifeStage, setActiveStep }) {

  // eslint-disable-next-line
  const [investment, setInvestment] = useState(JSON.parse(sessionStorage.getItem('investments')));

  const activeAsset = (id) => {
    setActive(id);
  }

  let iValues = investment.filter((item) => {
    return item.checked === true
  })
  // eslint-disable-next-line
  const [name, setName] = useState(sessionStorage.getItem('full_name'));

  const [active, setActive] = useState(iValues[0].id);
  //eslint-disable-next-line
  const [maxCount, setCount] = useState(iValues.length);

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    console.log(iValues);
  }, [iValues]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [popupTitle, setPopupTitle] = useState();
  const [popupContent, setPopupContent] = useState();

  return (


    <Fragment>
      <div className="mainwrapper">

        <Modal show={show} onHide={handleClose} className='modal model-sm' dialogClassName='modal-container' keyboard centered>
          <ModalBody>
            <div className="p20">
              <div className="text-center">
                <span className="deleteicon" />
              </div>
              <div className="text-center mt10 color1e3 fw600 font18">
                {popupTitle}
              </div>
              <div className="text-center mt10 color485 fw500 font14">
                {popupContent}
              </div>
              <div className="smallbtn mt20">
                <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={handleClose}>Continue</button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <div className="form_title">
          <div className="mt30 font20 fw600">{name} Investment</div>
          <div className="font15 color495">we need to map a plan to match your goal. so we need your income info</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
            </ul>




            {active === 1 && (
              <MutualFund
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
              />)}
            {active === 2 && (
              <FixedDeposits
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
              />)}
            {active === 3 && (
              <Stocks
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
              />)}
            {active === 4 && (
              <Gold
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
              />)}
            {active === 5 && (
              <RealEstate
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
              />)}
            {active === 6 && (
              <PpfEpf
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
              />)}
            {active === 7 && (
              <Nps
                active={active}
                iValues={iValues}
                maxCount={maxCount}
                investment={investment}
                activeAsset={activeAsset}
                current={current}
                setCurrent={setCurrent}
                StepChange={StepChange}
                steps={steps}
                nextStep={nextStep}
                lifeStage={lifeStage}
                setPopupContent={setPopupContent}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
                setActiveStep={setActiveStep}
              />)}
            {/* Form End */}


          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              Add Investment details, once added Your FinnFit team will manage this portfolio for you, making additions or changes when needed.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}

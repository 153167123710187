import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import ProfileStepper from './pages/ProfileStepper'
import Qna from './pages/tallyso/Qna'
import Register from './pages/Register'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import AdminDashboard from './pages/AdminDashboard'
import AutoLogin from './pages/AutoLogin'
import FpReport from './pages/FpReport'
import HistoryFpReport from './pages/HistoryFpReport'
import Example from './pages/Chart'
import ULogin from './pages/ULogin'
import CashflowWizard from './pages/CashflowWizard'
import ThirdPartyApi from './pages/ThirdPartyApi'
import AdmRiskHistory from './pages/AdmRiskHistory'
import RiskSet from './pages/RiskSet'
import FinancialOpd from './pages/FinancialOpd'
import QnaResponse from './pages/tallyso/QnaResponse'
import ReportProfileTimeline from './pages/ReportProfileTimeline'
import ReportProfilePlanStatus from './pages/ReportProfilePlanStatus'
import ReportPlanSummaryDrilldownMonth from './pages/ReportPlanSummaryDrilldownMonth'
import ReportPlanSummaryDrilldownRelMonthWise from './pages/ReportPlanSummaryDrilldownRelMonthWise'
import ReportPlanSummaryDrilldownRelManager from './pages/ReportPlanSummaryDrilldownRelManager'
import GuestUsersDashboard from './pages/GuestUsersDashboard'
import ProfileEventLogsDashboard from './pages/ProfileEventLogsDashboard'
import PlanLoginLogoutLogs from './pages/PlanLoginLogoutLogs'
import ULoginSetPassword from './pages/ULoginSetPassword'

import ManageAdminUsers from './pages/ManageAdminUsers'
import AdminDashboardNew from './pages/AdminDashboardNew'
import RiskSetLink from './pages/RiskSetLink'
import RiskProfileView from './pages/RiskProfileView'
 
import GuestUsersDashboardNew from './pages/GuestUsersDashboardNew'
import PlanLoginLogoutLogsNew from './pages/PlanLoginLogoutLogsNew'
import ReportProfileTimelineNew from './pages/ReportProfileTimelineNew'
import ReportProfilePlanStatusNew from './pages/ReportProfilePlanStatusNew'
import ReportPlanSummaryDrilldownMonthNew from './pages/ReportPlanSummaryDrilldownMonthNew'
import ProfileEventLogsDashboardNew from './pages/ProfileEventLogsDashboardNew'
import BlankPage from './pages/BlankPage'
import ManageAdminUsersNew from './pages/ManageAdminUsersNew'
import ReportPlanSummaryDrilldownRelManagerNew from './pages/ReportPlanSummaryDrilldownRelManagerNew'
import ReportPlanSummaryDrilldownRelMonthWiseNew from './pages/ReportPlanSummaryDrilldownRelMonthWiseNew'



export default function RouterPage() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/sign-in" replace />} />
                    <Route path="/home" element={<Home />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/fp-report" element={<FpReport />}></Route>
                    <Route path="/history-fp-report" element={<HistoryFpReport />}></Route>
                    <Route path="/basic-profile" element={<ProfileStepper />}></Route>
                    <Route path="/finnfit" element={<Qna />}></Route>
                    <Route path="/finnfit-thank-you" element={<QnaResponse />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/register" element={<Register />}></Route>
                    <Route path="/sign-in" element={<Login />}></Route>
                    <Route path="/ulogin" element={<ULogin />}></Route>
                    <Route path="/auto-sign-in" element={<AutoLogin />}></Route>
                    <Route path="/lms-login" element={<AutoLogin />}></Route>
                    <Route path="/recharts" element={<Example />}></Route>
                    <Route path="/cashflow-wizard" element={<CashflowWizard />}></Route>
                    <Route path="/finnovate-restapi" element={<ThirdPartyApi />}></Route>
                    <Route path="/risk-history" element={<AdmRiskHistory />}></Route>
                    <Route path="/risk-profile-set" element={<RiskSet />}></Route>
                    <Route path="/financialopt-leads" element={<FinancialOpd />}></Route>
                    <Route path="/plan-profiles-timeline-old" element={<ReportProfileTimeline />}></Route>
                    <Route path="/plan-profiles-timeline" element={<ReportProfileTimelineNew />}></Route>
                    <Route path="/plan-profiles-status-old" element={<ReportProfilePlanStatus />}></Route>
                    <Route path="/plan-profiles-status" element={<ReportProfilePlanStatusNew />}></Route>
                    <Route path="/ulogin-set-password" element={<ULoginSetPassword />}></Route> 
                    <Route path="/admin-dashboard-old" element={<AdminDashboard />}></Route> 
                    <Route path="/admin-dashboard" element={<AdminDashboardNew />}></Route> 
                    <Route path="/manage-admin-users-old" element={<ManageAdminUsers />}></Route>  
                    <Route path="/manage-admin-users" element={<ManageAdminUsersNew />}></Route>   
                    <Route path="/guest-users-dashboard-old" element={<GuestUsersDashboard />}></Route>
                    <Route path="/guest-users-dashboard" element={<GuestUsersDashboardNew />}></Route> 
                    <Route path="/profile-event-logs-dashboard-old" element={<ProfileEventLogsDashboard />}></Route>
                    <Route path="/profile-event-logs-dashboard" element={<ProfileEventLogsDashboardNew />}></Route>
                    <Route path="/plan-login-logs-dashboard-old" element={<PlanLoginLogoutLogs />}></Route>
                    <Route path="/plan-login-logs-dashboard" element={<PlanLoginLogoutLogsNew />}></Route> 

                    <Route path="/plan-summary-report-drilldown-month-old" element={<ReportPlanSummaryDrilldownMonth />}></Route>
                    <Route path="/plan-summary-report-drilldown-month" element={<ReportPlanSummaryDrilldownMonthNew />}></Route>
                    <Route path="/plan-summary-report-drilldown-month-relmanagerwise-old" element={<ReportPlanSummaryDrilldownRelManager />}></Route>
                    <Route path="/plan-summary-report-drilldown-month-relmanagerwise" element={<ReportPlanSummaryDrilldownRelManagerNew />}></Route>
                    <Route path="/plan-summary-report-drilldown-relmanager-monthwise-old" element={<ReportPlanSummaryDrilldownRelMonthWise />}></Route>
                    <Route path="/plan-summary-report-drilldown-relmanager-monthwise" element={<ReportPlanSummaryDrilldownRelMonthWiseNew />}></Route>

                    <Route path="/risk-profile-link" element={<RiskSetLink />}></Route>
                    
                    <Route path="/risk-profile-view" element={<RiskProfileView />}></Route>
                    <Route path="/blank-page" element={<BlankPage />}></Route>

                </Routes>
            </BrowserRouter>
        </div>
    )
}

import { useEffect } from 'react';

const DynamicScripts = ({ scripts = [], removeScriptSrcs = [] }) => {
  useEffect(() => {
    // Remove specific scripts if `removeScriptSrcs` is provided
    if (Array.isArray(removeScriptSrcs) && removeScriptSrcs.length > 0) {
      removeScriptSrcs.forEach((src) => {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript) {
          existingScript.parentNode.removeChild(existingScript);
          console.log(`Script removed successfully: ${src}`);
        }
      });
    }

    // Add new scripts dynamically
    const addedScripts = [];
    if (Array.isArray(scripts) && scripts.length > 0) {
      scripts.forEach((src) => {
        if (!document.querySelector(`script[src="${src}"]`)) {
          const script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          script.async = true; // Optional: Load asynchronously
          document.body.appendChild(script);
          addedScripts.push(script);
        }
      });
    }

    // Cleanup effect for all added scripts
    return () => {
      addedScripts.forEach((script) => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      });
    };
    // eslint-disable-next-line
  }, []);    
//  }, [JSON.stringify(scripts), JSON.stringify(removeScriptSrcs)]);

  return null; // This component doesn't render anything
};

export default DynamicScripts;

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend
} from 'recharts';


export default function Example() {
 
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];


  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;

    if (value > 2500) {
      return (



        <svg x={cx - 10} y={cy - 10} width={30} height={30} viewBox="0 0 512.001 512.001" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M433.675,17.241l-2.371-14.7l-14.778-1.826C415.934,0.64,410.503,0,401.866,0 c-28.25,0-82.101,6.456-125.34,49.695c-52.553,52.553-38.953,127.681-38.349,130.853l2.431,12.751l12.656,2.879 c1.451,0.331,14.724,3.224,33.725,3.224c28.65,0,69.433-6.631,101.023-38.222C446.653,102.538,434.233,20.696,433.675,17.241z"></path> <rect x="236.166" y="25.966" width="39.674" height="277.716"></rect> <path d="M399.955,121.243c-16.388-6.181-33.117-9.315-49.728-9.315c-35.975,0-69.194,14.433-94.226,40.318 c-25.033-25.884-58.252-40.318-94.226-40.318c-16.61,0-33.34,3.134-49.727,9.315c-51.904,19.579-81.672,66.796-81.672,129.543 c0,110.776,59.475,201.062,118.442,241.436c18.557,12.94,41.471,19.78,66.27,19.78c13.693,0,27.695-2.183,40.914-6.349 c13.218,4.164,27.22,6.349,40.914,6.349c0.003,0,0.003,0,0.007,0c24.792,0,47.702-6.838,66.258-19.775 c57.064-39.065,118.449-131.254,118.449-241.44C481.627,188.039,451.859,140.822,399.955,121.243z"></path> <path d="M399.955,121.243c-16.388-6.181-33.117-9.315-49.728-9.315c-35.975,0-69.194,14.433-94.226,40.318 v353.407c13.218,4.164,27.22,6.349,40.914,6.349c0.003,0,0.003,0,0.007,0c24.792,0,47.702-6.838,66.258-19.775 c57.064-39.065,118.449-131.254,118.449-241.44C481.627,188.039,451.859,140.822,399.955,121.243z"></path> </g></svg>

      );
    }

    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
      </svg>
    );
  };

  return (
    <div className="line-charts">


      <div className="line-chart-wrapper">
        <LineChart
          width={600}
          height={500}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="pv" stroke="#8884d8" dot={<CustomizedDot />} />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      </div>

    </div>
  );

}
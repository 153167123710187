import React, { Fragment, useEffect, useState } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
//import { Encryption } from './global'

export default function EditAdminUser({ setActive, setAction, adminId }) {

    const Back = () => {
        setActive(false);
        setAction('');
    }

    const [adminData, setAdminData] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [reporting, setReporting] = useState([]);


    useEffect(() => {
        axioInstance.get(`admin-users`).then(
            (response, data) => {

                let self_admin = response.data.data.find(values => values.admin_id === adminId);
                setAdminData(self_admin);

                setReporting(response.data.data);
                setEmployee(response.data.employee);
            }
        );
    }, [adminId])

    // eslint-disable-next-line
    const { register, handleSubmit, setError, formState: { errors }, setValue } = useForm();

    useEffect(() => {
        if (adminData) {
            setValue("fullName", adminData.admin_name);
            setValue("emailAddress", adminData.email_id);
            setValue("rel_manager", adminData.short_name)
            setValue("admin_id", adminData.admin_id)
            setValue("updated_by", sessionStorage.getItem("admin_id"))
            setValue("report_to", adminData.report_to)
            setValue("rel_manager", adminData.rel_manager ?? adminData.short_name)
            setValue("ser_manager", adminData.ser_manager)
            setValue("bo_user", adminData.bo_user)
        }
    }, [adminData, setValue]);

    const onSubmit = formData => {


        axioInstance.put(`/admin-users/update-managers/${formData.admin_id}`, {
            fullName: formData.fullName,
            emailAddress: formData.emailAddress,
            report_to: formData.report_to,
            rel_manager: formData.rel_manager,
            ser_manager: formData.ser_manager,
            bo_user: formData.bo_user,
            updated_by: formData.updated_by
        }).then(function (response) {

            SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Admin User Successfully Updated.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {
                window.location.reload();
            });

        }).catch(function (error) {
            // Handle error
            SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Unable to update Admin User, Something went wrong.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {
                //nothing
            });
        });

    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Edit Admin
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Edit Admin User</div>
                        <div className="font14 fw500 color485">Finnovate Users</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
                            <input type='hidden' name='rel_manager' />
                            <input type='hidden' name='admin_id' />
                            <input type='hidden' name='updated_by' />

                            <div className="form-group">
                                <label>Admin Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                    readOnly={true}
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                    readOnly={true}
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div class="form-group">
                                <label>Assign Report To</label>
                                <select name="report_to" id="report_to" class="form-control" autocomplete="off" {...register("report_to", { required: "Report To is required" })}>
                                    <option disabled="" value="">Select Report To</option>
                                    {
                                        reporting.map((admin) => {

                                            return (
                                                <>
                                                    <option value={admin.admin_id}>{admin.admin_name}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {errors.report_to && <span className='redtext'>{errors.report_to.message}</span>}
                            </div>


                            <div class="form-group">
                                <label>Assign Service Manager</label>
                                <select name="ser_manager" id="ser_manager" class="form-control" autocomplete="off" {...register("ser_manager", { required: "Report To is required" })}>
                                    <option disabled="" value="">Select Service Manager</option>
                                    {
                                        employee.map((emp) => {

                                            return (
                                                <>
                                                    <option value={emp.short_name}>{emp.short_name}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {errors.ser_manager && <span className='redtext'>{errors.ser_manager.message}</span>}
                            </div>


                            <div class="form-group">
                                <label>Assign BO User</label>
                                <select name="bo_user" id="bo_user" class="form-control" autocomplete="off" {...register("bo_user", { required: false })}>
                                    <option disabled="" value="">Select BO user</option>
                                    {
                                        employee.map((emp) => { 
                                            return (
                                                <>
                                                    <option value={emp.short_name}>{emp.short_name}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {errors.bo_user && <span className='redtext'>{errors.bo_user.message}</span>}
                            </div>


                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

import React, { useState, useEffect, Fragment } from 'react';
import axioInstance from '../components/axiosInstance';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { apiUrl } from '../components/global';
import FinnLoader from '../components/FinnLoader';

const friendsArray = [
    { ques_id: 152, key: 'gender', ans_id: null },
    { ques_id: 153, key: 'age', ans_id: null },
    { ques_id: 154, key: 'occupation', ans_id: null },
    { ques_id: 155, key: 'IncomeSelf', ans_id: null },
    { ques_id: 156, key: 'IncomeSpouse', ans_id: null },
    { ques_id: 157, key: 'expense_perc', ans_id: null },
    { ques_id: 158, key: 'emi_perc', ans_id: null },
    { ques_id: 159, key: 'dependents', ans_id: null },
    { ques_id: 160, key: 'exp_inv_equity_mf', ans_id: null },
    { ques_id: 161, key: 'illustrative_portfolio', ans_id: null },
    { ques_id: 162, key: 'worry', ans_id: null },
    { ques_id: 163, key: 'financial_decission', ans_id: null },
    { ques_id: 164, key: 'equity_perc', ans_id: null },
    { ques_id: 165, key: 'acco_status', ans_id: null },
    { ques_id: 166, key: 'invest_knowledge', ans_id: null }
];

export default function RiskSetLink() {

    const [riskq, setRiskq] = useState([]);
    const [steps, setSteps] = useState(0);
    const [email, setEmail] = useState('');
    const [apiCall, setApiCall] = useState(false);
    const [showEmailScreen, setShowEmailScreen] = useState(true);
    const [friends, setFriends] = useState(friendsArray);

    const [loading, setLoading] = useState(false);
    const handleNextClick = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (email.trim() === '') {
            SweetAlert.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Please enter an email address.',
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }

        if (!emailPattern.test(email)) {
            SweetAlert.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Please enter a valid email address.',
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }

        setShowEmailScreen(false);
    };


    const previousButton = () => {
        if (steps === 0) {
            setShowEmailScreen(true);
        } else {
            setSteps(steps - 1);
        }
    };

    const stepNext = (e) => {
        let ques_id = e.target.getAttribute("ques_id");
        setFriends((prevFriends) =>
            prevFriends.map((friend) =>
                friend.ques_id === parseInt(ques_id) ? { ...friend, ans_id: e.target.value } : friend
            )
        );

        if (steps === 14) {
            console.log('Submit');
            setApiCall(true);
        } else {
            setSteps(steps + 1);
        }
    };


    useEffect(() => {
        if (apiCall) {
            setLoading(true);
            const postData = {
                "set_id": 3,
                "fname": "",
                "lname": "",
                "mobile": 9999999999,
                "profile_id": "",
                "dob": "",
                "email": email,
                "weight": 1,
                "goaltype_id": 7,
                "admin_id": "1",
                "form_data": friends,
                "ref_url": window.location.href,
                "utm_source": 'LINK',
                "utm_campaign": 'ET'
            };

            const headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            };

            const submitRiskProfile = async () => {
                try {
                    const response = await axios.post(`${apiUrl}risk/generateriskscore`, postData, { headers });

                    if (response.data.status_code === 100) {
                        SweetAlert.fire('Risk Profile', 'Successfully created risk. Thank You.', 'success')
                            .then(() => { window.location.href = '/sign-in'; });
                    } else {
                        SweetAlert.fire('Risk Profile', 'Something went wrong. Please try again after sometimes.', 'Error').then(() => { window.location.reload() });
                    }
                    setLoading(false);

                } catch (error) {
                    setLoading(false);
                    SweetAlert.fire('Risk Profile', 'Something went wrong. Please try again after sometimes.', 'Error').then(() => { window.location.reload() });
                }
            };

            submitRiskProfile();
        }
    }, [apiCall, email, friends]);


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const emailFromUrl = searchParams.get('email') || '';
        setEmail(emailFromUrl);

        axioInstance.post(`url-logged`, { url: window.location.href });

        axios.post(`${apiUrl}risk/getriskquestions`, { email: emailFromUrl })
            .then((response) => {
                setRiskq(response.data.response_data.question);
                sessionStorage.setItem('questions', JSON.stringify(response.data.response_data.question));
            })
            .catch((error) => {
                console.error('Error fetching risk questions:', error);
            });
    }, []);

    return (
        <Fragment>
            {loading && <FinnLoader />}
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className='form_title mt30 font20 fw600'>Risk Profile</div>
                    <div className="font15 color495 ml20">Few questions to understand your Risk Profile</div>
                    <div className="row formWpr mt25">


                        {
                            showEmailScreen ? (

                                <Fragment>

                                    <div className="col-lg-12 formouter">
                                        <div className="formtitle flex_center justify_center">
                                            <div>
                                                <div className="font20 fw600">Risk Profile</div>
                                                <div className="font15 color495">Set your Risk Profile</div>
                                            </div>
                                            <button type="button" className="btn btn-primary d-none d-md-block" onClick={handleNextClick}>Start</button>
                                        </div>
                                        <div className="p35">
                                            <ul className="risk_tag font14 fw500 color182 mt15">
                                                <div className="color212 font14 fw600 mb25">Your Email Address:<span className="colorfa8">*</span></div>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    defaultValue={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </ul>
                                        </div>
                                    </div>

                                </Fragment>




                            ) : (
                                <Fragment>
                                    <div className="col-lg-12 formouter">
                                        <div className="formtitle flex_center justify_center">
                                            <div>
                                                <div className="font20 fw600">Risk Profile</div>
                                                <div className="font15 color495">Set your Risk Profile</div>
                                            </div>
                                            <button type="button" className="btn btn-primary d-none d-md-block" onClick={previousButton}>Previous</button>
                                        </div>
                                        <div className="p35">
                                            <ul className="risk_tag font14 fw500 color182 mt15">
                                                {riskq.map((item, index) => (
                                                    <div key={item.ques_id} className={steps === index ? 'show' : 'hide'}>
                                                        <div className="color212 font14 fw600 mb25">{item.label} <span className="colorfa8">*</span></div>
                                                        {item.answers.map(company => (
                                                            <li key={company.ans_id}>
                                                                <label>
                                                                    <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onClick={stepNext} />
                                                                    <span className="rdo_text_box">{company.name}</span>
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }

                    </div>
                    <div className="saveBtn backsaveBtn d-block d-sm-none">

                        {
                            showEmailScreen ? <button type="button" className="btn btn-primary" onClick={handleNextClick}>Start</button> : <button type="button" className="btn btn-primary" onClick={previousButton}>Previous</button>
                        }
                        
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

import React, { Fragment, useEffect, useState } from 'react'
import SideImg from '../../img/Protection.png';
import { useForm } from 'react-hook-form';
import axioInstance from '../axiosInstance';
import { sanitizeNumber, QuoteSection } from '../global';
import formatAmount from 'indian-currency-formatter';
import SweetAlert from 'sweetalert2';

export default function CashflowScreen1({ setSubstep, substep, nextsubStep, clientName, incomeSelfParam, setIncomeSelfParam, incomeSpouseParam, setIncomeSpouseParam }) {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const [incomeData, setIncomeData] = useState({
        IncomeSelf: { amount: null, freq: 12, id: null },
        IncomeSpouse: { amount: 0, freq: 12, id: null },
        Bonus: { amount: 0, freq: 12, id: null },
        RentalIncome: { amount: 0, freq: 12, id: null },
        InvestmentIncome: { amount: 0, freq: 12, id: null }
    });

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        if (profile_id !== null) {
            axioInstance.post(`get-income-expense`, { profile_id, type: 1 }).then((response) => {
                if (response.data.length > 0) {
                    const updatedIncomeData = { ...incomeData };
                    response.data.forEach(item => {
                        const { type_name_sf, income_id, frequency, amount } = item;
                        if (updatedIncomeData[type_name_sf]) {
                            updatedIncomeData[type_name_sf] = {
                                amount: type_name_sf !== "IncomeSelf" ? formatAmount(amount) : (type_name_sf === "IncomeSelf" && income_id !== null) ? formatAmount(amount) : '', freq: frequency, id: income_id
                            };
                        }
                    });
                    setIncomeData(updatedIncomeData);
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIncomeData(prevData => ({
            ...prevData,
            [name]: {
                ...prevData[name],
                amount: formatAmount(sanitizeNumber(value))
            }
        }));
    };

    const onSubmit = data => {
        setButtonSpinner(true);

        const profile_id = sessionStorage.getItem('profile_id');
        const admin_id = sessionStorage.getItem('admin_id');

        const Postdata = {
            profile_id,
            admin_id,
            type: 1,
            consider: 1,
            step: 2,
            income: Object.keys(incomeData).reduce((acc, key) => {
                acc[key] = {
                    sub_type: key,
                    amount: sanitizeNumber(incomeData[key].amount),
                    frequency: data[`${key}Freq`] || 12
                };
                return acc;
            }, {})
        };

        axioInstance.post(`profile-income`, Postdata)
            .then(response => {
                setButtonSpinner(false);
                sessionStorage.setItem("incomeData", JSON.stringify(data));
                setSubstep(2);
            })
            .catch(() => {
                SweetAlert.fire('Income', 'Failed. Something went wrong. Retry again', 'error');
                setButtonSpinner(false);
            });
    };


    const InputVal = (e, type) => {
        const inputAmount = sanitizeNumber(e.target.value);
        const fieldName = e.target.name;

        console.log('inputAmount', inputAmount);

        const formattedAmount = inputAmount === "0" ? '' : formatAmount(inputAmount);

        if (type === 1 || (type === 2 && inputAmount > 0)) {
            setIncomeData(prevData => ({
                ...prevData,
                [fieldName]: {
                    ...prevData[fieldName],
                    amount: formattedAmount
                }
            }));
        } else if (type === 2 && inputAmount <= 0) {
            setIncomeData(prevData => ({
                ...prevData,
                [fieldName]: {
                    ...prevData[fieldName],
                    amount: 0
                }
            }));
        }
    };


    useEffect(() => {

        reset(incomeData);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className="form_title">
                        <div className="mt30 font20 fw600">{clientName} Cashflow</div>
                        <div className="font15 color495">We need to map a plan to match your goal, so we need your income info</div>
                    </div>
                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">
                            <ul className="form_swipe_tab font12 fw600">
                                <li className="act">1</li>
                                <li>2</li>
                                <li>3</li>
                                <li>4</li>
                            </ul>

                            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
                                <input type="hidden" name="IncomeSelfId" {...register("IncomeSelfId")} />
                                <input type="hidden" name="IncomeSpouseId" {...register("IncomeSpouseId")} />
                                <input type="hidden" name="IncomeBonusId" {...register("IncomeBonusId")} />
                                <input type="hidden" name="IncomeRentalIncomeId" {...register("IncomeRentalIncomeId")} />
                                <input type="hidden" name="IncomeInvestmentIncomeId" {...register("IncomeInvestmentIncomeId")} />

                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Income</div>
                                        <div className="font15 color495">An approximate idea of your income is enough</div>
                                    </div>
                                    <div>
                                        <button
                                            type={buttonSpinner ? "button" : "submit"}
                                            className="bluebtn font13 fw600 d-none d-md-block"
                                            disabled={!incomeData.IncomeSelf.amount}
                                        >
                                            {buttonSpinner ? (
                                                <>
                                                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading
                                                </>
                                            ) : "Save"}
                                            <span className="whitearrow">
                                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="p35">
                                    <div className="addincome_wpr">
                                        <div className="font16 fw600 color212">Family’s earned income</div>
                                    </div>

                                    <div className="form-row mt20">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Self income <span className="colorfa8">*</span></label>
                                            <div className="input-group selectarrow">
                                                <input
                                                    type="text"
                                                    className="form-control color212 font14 fw500"
                                                    name="IncomeSelf"
                                                    {...register("IncomeSelf", { required: 'Income Self is required' })}
                                                    value={incomeData.IncomeSelf.amount || ''}
                                                    onChange={handleChange}
                                                    onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)}
                                                />
                                                <select className="custom-select" name="IncomeSelfFreq" {...register("IncomeSelfFreq")} defaultValue={incomeData.IncomeSelf.freq}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger">{errors.IncomeSelf && errors.IncomeSelf.message}</span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Spouse income</label>
                                            <div className="input-group selectarrow">
                                                <input
                                                    type="text"
                                                    className="form-control color212 font14 fw500"
                                                    name="IncomeSpouse"
                                                    {...register("IncomeSpouse")}
                                                    value={incomeData.IncomeSpouse.amount}
                                                    onChange={handleChange}
                                                    onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)}
                                                />
                                                <select className="custom-select" name="IncomeSpouseFreq" {...register("IncomeSpouseFreq")} defaultValue={incomeData.IncomeSpouse.freq}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>

                                    <div className="formdevider mt35 mb35" />

                                    <div className="addincome_wpr">
                                        <div className="font16 fw600 color212">Family’s other income</div>
                                    </div>

                                    <div className="form-row mt25">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Rental</label>
                                            <div className="input-group selectarrow">
                                                <input
                                                    type="text"
                                                    className="form-control color212 font14 fw500"
                                                    name="RentalIncome"
                                                    {...register("RentalIncome")}
                                                    value={incomeData.RentalIncome.amount}
                                                    onChange={handleChange}
                                                    onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)}
                                                />
                                                <select className="custom-select" name="RentalIncomeFreq" {...register("RentalIncomeFreq")} defaultValue={incomeData.RentalIncome.freq}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Investment Income</label>
                                            <div className="input-group selectarrow">
                                                <input
                                                    type="text"
                                                    className="form-control color212 font14 fw500"
                                                    name="InvestmentIncome"
                                                    {...register("InvestmentIncome")}
                                                    value={incomeData.InvestmentIncome.amount}
                                                    onChange={handleChange}
                                                    onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)}
                                                />
                                                <select className="custom-select" name="InvestmentIncomeFreq" {...register("InvestmentIncomeFreq")} defaultValue={incomeData.InvestmentIncome.freq}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-row mt25">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Variable/Bonus</label>
                                            <div className="input-group selectarrow">
                                                <input
                                                    type="text"
                                                    className="form-control color212 font14 fw500"
                                                    name="Bonus"
                                                    {...register("Bonus")}
                                                    value={incomeData.Bonus.amount}
                                                    onChange={handleChange}
                                                    onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)}
                                                />
                                                <select className="custom-select" name="BonusFreq" {...register("BonusFreq")} defaultValue={incomeData.Bonus.freq}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="saveBtn d-block d-sm-none">
                                    <button
                                        type={buttonSpinner ? "button" : "submit"}
                                        className="btn btn-primary"
                                        disabled={!incomeData.IncomeSelf.amount}
                                    >
                                        {buttonSpinner ? (
                                            <>
                                                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading
                                            </>
                                        ) : "Save and Continue"}
                                        <span className="whitearrow">
                                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <QuoteSection
                            text="If your parents are getting pension, or if you inherit any other income, please just click add income."
                            imageName={SideImg}
                            altText="Cashflow"
                        />
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

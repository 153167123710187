import React, {  useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import {  useNavigate } from 'react-router-dom' 
import NewTheme from '../components/admin-dashboard/NewTheme';
import SidebarPopupComponent from '../components/admin-dashboard/SidebarPopupComponent';
import TableContainer from '../components/admin-dashboard/TableContainer';

export default function ReportProfilePlanStatusNew() {

  let navigate = useNavigate();
  
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [planStatus, setPlanStatus] = useState('');

   // eslint-disable-next-line
  const [selectedPlanner, setSelectedPlanner] = useState('');
   
  // eslint-disable-next-line
  const [selectedRelManager, setSelectedRelManager] = useState('');

  // State for the input values
  /* eslint-disable no-unused-vars */
  const [filters, setFilters] = useState({
    plan_status: ''
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const plan_status = params.get('plan_status') ? params.get('plan_status') : '';

      var PostData = '';

      if(plan_status){
        setPlanStatus(plan_status);
        PostData = {
          plan_status: plan_status
        }
      }
      axioInstance.post(`admin/plan-profiles-status`, PostData)
      .then((response, data) => {
                //console.log(response.data);
                setData(response.data.data);
                setTableRowsData(response.data.data);
              //  setLoading(false);
              }
            ).catch((error) => {
              console.error("Error fetching data:", error);
            })
            .finally(() => {
              setLoading(false); // Turn off the loader
            });


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);

  const uniquePlanners = [...new Set(data.map(item => item.admin_name))].filter(planner => planner).sort((a, b) => a.localeCompare(b));

  const uniqueRelManager = [...new Set(data.map(item => item.primary_rel_manager))].filter(manager => manager).sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    let filteredData = data;

    if (selectedPlanner !== '') {
      filteredData = filteredData.filter(item => item.admin_name === selectedPlanner);
    }

    if (selectedRelManager !== '') {
      filteredData = filteredData.filter(item => item.primary_rel_manager === selectedRelManager);
    }

    setTableRowsData(filteredData);
  }, [selectedPlanner, selectedRelManager, data]);
 


  // eslint-disable-next-line
  const [relManager, setRelManager] = useState([])
 
  const [trigger, setSidebarTrigger] = useState(false); 

  
  const handleInputChange = (e) => {
    setPlanStatus(e.target.value);
    const { id, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value
    }));
  };

  const handleFilter = () => { 
    setLoading(true); 
    //const queryParams = new URLSearchParams(filters).toString();
    //const url = `plan-profiles-status?${queryParams}`;
    //navigate(`?plan_status=` + planStatus );
    window.location.href = `plan-profiles-status?plan_status=` + planStatus

  }
 
  useEffect(() => {
    setTimeout(()=>{
    // Ensure the DOM is ready and `applyDataTable` is available globally
    if (typeof window.applyDataTable === "function") {
  
        window.applyDataTable(
          '#datatable_2', 
          {
            "bFilter": true,
          } , 
          function(datatable) {
            
            // Add datatable filter relManager 
              const fileter_relManager = document.getElementById('relManager');
              if (fileter_relManager) {
                  fileter_relManager.addEventListener('change', function() {
                    datatable.column(2).search(this.value).draw();
                  });
              }

              // Add datatable filter primaryPlanner 
              const primaryPlanner = document.getElementById('primaryPlanner');
              if (primaryPlanner) {
                  primaryPlanner.addEventListener('change', function() {
                    datatable.column(3).search(this.value).draw();
                  });
              } 
          }
        );
    }
  }, 4000) 
  }, []);   

  const resetFilter = () => { 
    window.location.href = 'plan-profiles-status'; 
  }

  return (
    <NewTheme loading={loading}> 
        <div className="page-header">
            <div className="content-page-header">
            <h5>PLAN Profile Status</h5>
            <div className="list-btn"> 
                <ul className="filter-list">
                    <li>
                        <button onClick={()=>setSidebarTrigger(true)} className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                            <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                        </button>
                    </li>  
                </ul>  
            </div>
            </div>
        </div>
         
        <SidebarPopupComponent title='Filter Profile Plan Status Data' id="sidebar_1" trigger={trigger} setSidebarTrigger={setSidebarTrigger}>          
            <div className='row'> 
                <div className="form-group">
                <label htmlFor="relManager">REL Manager:(<small>Datatable filter</small>)</label>
                <select id="relManager" className="form-control">
                    <option value="">ALL</option>
                    {uniqueRelManager.map(manager => (
                        <option key={manager} value={manager}>{manager}</option>
                    ))}
                </select> 
                </div>

                <div className="form-group">
                <label htmlFor="primaryPlanner">Primary Planner:(<small>Datatable filter</small>)</label>
                <select id="primaryPlanner" className="form-control">
                    <option value="">ALL</option>
                    {uniquePlanners.map(planner => (
                    <option key={planner} value={planner}>{planner}</option>
                    ))}
                </select>

                </div>

                <div className="form-group">
                <label htmlFor="plan_status">Plan Status:(<small>Query filter</small>)</label>
                  <select id="plan_status" name='plan_status' className="form-control" onChange={handleInputChange} defaultValue={planStatus}>
                      <option value="">ALL</option>
                      <option value="1">In Progess</option>
                      <option value="3">Plan Completed</option>
                      <option value="5">Shared</option>
                      <option value="7">Freezed</option>
                      <option value="9">Execution Done</option>
                  </select>
                </div>

                <div className="filter-buttons">
                    <button type="button" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary" onClick={handleFilter}>
                        Filter
                    </button>
                    <button type="button" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary"  onClick={resetFilter}>
                        Reset
                    </button>
                </div> 
            </div> 
        </SidebarPopupComponent>

        <TableContainer>
            <table className="table table-stripped table-hover " id="datatable_2">
                <thead className="thead-light">
                    <tr>
                    <th title='profile_id'>ID</th>
                    <th>Name</th>
                    <th>Primary Rel Manager</th>
                    <th>Primary Planner</th>
                    <th>Status</th>
                    <th>Status Date</th>
                    <th>Monthly</th>
                    <th>Yearly</th>
                    <th>Increase By</th>
                    <th>Created At</th>                
                    </tr>
                </thead>
                <tbody>
                    {tableRowsData.map((row, index) => {
                    return <tr key={index}>
                              <td>{row.profile_id}</td>
                              <td>{row.full_name}</td>
                              <td>{row.primary_rel_manager}</td>
                              <td>{row.admin_name }</td>
                              <td>{row.status}</td>  
                              <td>{row.status_created_date}</td>  
                              <td>{row.monthly_amount}</td>  
                              <td>{row.yearly_amount}</td>  
                              <td>{row.increase_by}</td>  
                              <td>{row.created_date}</td>  
                          </tr>
                    })}
                </tbody>
            </table>
        </TableContainer>  
    </NewTheme>
  );
}
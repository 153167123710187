import React, { Fragment } from 'react';

export default function AddChild({ childCount, register, errors }) {
  return (
    <Fragment>
      {[...Array(childCount)].map((e, i) => (
        <div className="form-row" key={i}>
          <div className="form-group col-md-6 pr20">
            <label htmlFor={'childName' + i} className="font14 fw600">Child {i + 1} <span className="colorfa8">*</span></label>
            <input
              type="text"
              className="form-control"
              id={'childName' + i}
              name={`childsArr[${i}].name`}
              autoComplete='off'
              {...register(`childsArr.${i}.name`, { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: { value: 50, message: 'Name is too long' } })}
              placeholder="Enter Child Name"
            />
            <span className="text-danger"> {errors.childsArr?.[i]?.name?.message}</span>
          </div>

          <div className="form-group col-md-6 pl20">
            <label htmlFor={'childAge' + i} className="font14 fw600">Age <span className="colorfa8">*</span></label>
            <div className="input-group selectarrow">
              <input
                type="text"
                className="form-control"
                id={'childAge' + i}
                name={`childsArr[${i}].age`}
                autoComplete='off'
                {...register(`childsArr.${i}.age`, { required: 'Age is required', min: { value: 0, message: 'Min value is 1' } })}
                placeholder="Enter Child Age"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <select
                className="custom-select font14"
                id="inputGroupSelect01"
                name={`childsArr[${i}].ageType`}
                {...register(`childsArr.${i}.ageType`)}
              >
                <option value="years">Year</option>
                <option value="months">Months</option>
                <option value="days">Days</option>
              </select>
            </div>
            <span className="text-danger"> {errors.childsArr?.[i]?.age?.message}</span>
          </div>

          <input
            type="hidden"
            className="form-control"
            id={'childProfDetId' + i}
            name={`childsArr[${i}].child_prof_det_id`}
            autoComplete='off'
            {...register(`childsArr.${i}.child_prof_det_id`)}
          />
        </div>
      ))}
    </Fragment>
  );
}

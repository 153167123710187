import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import { sanitizeNumber } from '../global';
import formatAmount from 'indian-currency-formatter';
import SideImg from '../../img/Protection.png';
import axioInstance from '../axiosInstance';
import { Encryption, QuoteSection } from '../global';

export default function CashflowScreen1({ setSubstep, substep, prevsubStep, nextsubStep, clientName, incomeSelfParam, incomeSpouseParam }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [expenseData, setExpenseData] = useState({
    Household: { amount: null, freq: 12, id: null },
    rentalExpense: { amount: 0, freq: 12, id: null },
    UtilityBills: { amount: 0, freq: 12, id: null },
    Lifestyle: { amount: 0, freq: 12, id: null },
    ChildrenEducation: { amount: 0, freq: 12, id: null },
    Vacation: { amount: 0, freq: 12, id: null }
  });


  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null) {
      axioInstance.post(`get-income-expense`, { profile_id, type: 2 }).then((response) => {
        if (response.data.length > 0) {
          const updatedExpenseData = { ...expenseData };
          response.data.forEach(item => {
            const { type_name_sf, income_id, frequency, amount } = item;
            if (updatedExpenseData[type_name_sf]) {
              updatedExpenseData[type_name_sf] = {
                amount: type_name_sf !== "Household" ? formatAmount(amount) : (type_name_sf === "Household" && income_id !== null) ? formatAmount(amount) : '', freq: frequency, id: income_id
              };
            }
          });
          setExpenseData(updatedExpenseData);
        }
      });

      axioInstance.post(`profile-details/getfamily`, { profile_id: profile_id }).then(
        (response, data) => {

          let spouse_prof_det_id = response.data.find(product => product.relation === "spouse");

          sessionStorage.setItem('spouse_prof_det_id', spouse_prof_det_id ? Encryption(spouse_prof_det_id.profile_details_id) : null);


        });

    }
    // eslint-disable-next-line
  }, []);



  const onSubmit = data => {

    setButtonSpinner(true);

    const profile_id = sessionStorage.getItem('profile_id');
    const admin_id = sessionStorage.getItem('admin_id');

    const Postdata = {
      profile_id,
      admin_id,
      type: 2,
      consider: 1,
      step: 2,
      income: Object.keys(expenseData).reduce((acc, key) => {
        acc[key] = {
          sub_type: key,
          amount: sanitizeNumber(expenseData[key].amount),
          frequency: data[`${key}Freq`] || 12
        };
        return acc;
      }, {})
    };


    axioInstance.post(`profile/add-expense`, Postdata)
      .then(function (response) {

        setButtonSpinner(false);
        setSubstep(nextsubStep(substep, 4))

      })
      .catch(function (error) {
        setButtonSpinner(false);
        SweetAlert.fire(
          'Expense',
          'Failed. Something went wrong. Retry again',
          'error'
        )
      });

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExpenseData(prevData => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        amount: formatAmount(sanitizeNumber(value))
      }
    }));
  };


  const InputVal = (e, type) => {
    const inputAmount = sanitizeNumber(e.target.value);
    const fieldName = e.target.name;

    const formattedAmount = inputAmount === "0" ? '' : formatAmount(inputAmount);

    if (type === 1 || (type === 2 && inputAmount > 0)) {
      setExpenseData(prevData => ({
        ...prevData,
        [fieldName]: {
          ...prevData[fieldName],
          amount: formattedAmount
        }
      }));
    } else if (type === 2 && inputAmount <= 0) {
      setExpenseData(prevData => ({
        ...prevData,
        [fieldName]: {
          ...prevData[fieldName],
          amount: 0
        }
      }));
    }
  };

  useEffect(() => {

    reset(expenseData);
    // eslint-disable-next-line
  }, []);


  return (
    <Fragment>

      <div className="mainwrapper">

        <div className='form_title'>
          <div className="mt30 font20 fw600">{clientName} Cashflow</div>
          <div className="font15 color495">we need to map a plan to match your goal. so we need your income infos.</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
              <li>3</li>
              <li>4</li>
            </ul>


            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Expenses</div>
                  <div className="font15 color495 ">Also a bit approx but give us an idea of all the expenses you have.</div>
                </div>
                <div>

                  <button type={buttonSpinner ? "button" : "submit"} className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}


              <div className="p35">

                <div className="addincome_wpr">
                  <div className="font16 fw600 color212">{clientName} family’s expenses</div>
                </div>


                <div className="form-row mt20">
                  <div className="col-md-6 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Household expenses<span className="colorfa8">*</span></label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Household" {...register("Household", { required: 'Household Expense is required' })} value={expenseData.Household.amount || ''} onChange={handleChange} />
                      <select className="custom-select" id="inputGroupSelect01" name="HouseholdFreq" {...register("HouseholdFreq")} value={expenseData.Household.freq}>
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.Household && errors.Household.message}</span>
                  </div>
                  <div className="col-md-6 pl20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Rent</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="rentalExpense" {...register("rentalExpense", { required: 'Rental Expense is required' })} value={expenseData.rentalExpense.amount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="rentalExpenseFreq" {...register("rentalExpenseFreq")} value={expenseData.rentalExpense.freq}>
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.rentalExpense && errors.rentalExpense.message}</span>
                  </div>
                </div>

                <div className="form-row mt20">
                  <div className="col-md-6 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Utility Bills</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="UtilityBills" {...register("UtilityBills", { required: 'Utility Bills Expense is required' })} value={expenseData.UtilityBills.amount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="UtilityBillsFreq" {...register("UtilityBillsFreq")} value={expenseData.UtilityBills.freq}>
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.UtilityBills && errors.UtilityBills.message}</span>
                  </div>
                  <div className="col-md-6 pl20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Lifestyle expenses</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Lifestyle" {...register("Lifestyle", { required: 'Lifestyle Expense is required' })} value={expenseData.Lifestyle.amount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="LifestyleFreq" {...register("LifestyleFreq")} value={expenseData.Lifestyle.freq}>
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.Lifestyle && errors.Lifestyle.message}</span>
                  </div>
                </div>

                <div className="formdevider mt35 mb35" />


                <div className="form-row mt25">
                  <div className="col-md-6 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Kids Education expenses</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="ChildrenEducation" {...register("ChildrenEducation", { required: 'Children Education Expense is required' })} value={expenseData.ChildrenEducation.amount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="ChildrenEducationFreq" {...register("ChildrenEducationFreq")} value={expenseData.ChildrenEducation.freq}>
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.ChildrenEducation && errors.ChildrenEducation.message}</span>
                  </div>
                  <div className="col-md-6 pl20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Vacation expenses</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Vacation" {...register("Vacation", { required: 'Vacation Expense is required' })} value={expenseData.Vacation.amount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="VacationFreq" {...register("VacationFreq")} value={expenseData.Vacation.freq}>
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.Vacation && errors.Vacation.message}</span>

                  </div>
                </div>
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid} className="btn btn-primary"> {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"} </button>
              </div>

            </form>

            {/* Form End */}

          </div>




          <QuoteSection
            text="If you have other expenses, please click add expenses button and add them."
            imageName={SideImg}
            altText="Cashflow"
          />

        </div>

      </div>
    </Fragment>
  )
}

import axios from 'axios';
import { toast } from 'react-toastify';

const apiUrl = window.location.hostname === "localhost"
    ? "https://fpapi.finnovate.in/api/"
    : window.location.hostname === "plan.finnovate.in"
        ? "https://planapi.finnovate.in/api/"
        : "https://fpapi.finnovate.in/api/";

const axioInstance = axios.create({
    baseURL: apiUrl,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    }
});

let isSessionExpired = false; // Flag to track token expiration

// Add a request interceptor to attach the token
axioInstance.interceptors.request.use(
    (config) => {
        if (isSessionExpired) {
            return Promise.reject(new axios.Cancel('Session expired, no further requests allowed.'));
        }

        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to handle token expiration globally
axioInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status, data } = error.response;

            // Check if token expired or unauthorized
            if (status === 401 || data?.message === "Token Expired") {
                if (!isSessionExpired) {
                    isSessionExpired = true; // Block further requests

                    toast.error("Session expired! Redirecting to sign-in...", {
                        position: "top-right",
                        autoClose: 3000,
                    });

                    setTimeout(() => {
                        sessionStorage.removeItem('token'); // Clear token
                        window.location.href = "sign-in"; // Redirect to login page
                    }, 3000);
                }
            } else {
                // Handle other errors

                if (sessionStorage.getItem('admin_id') === 1) {
                    toast.error(data?.message || "An error occurred!", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                } else {
                    toast.error("An error occurred. Please try again after sometimes", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                }

            }
        }
        return Promise.reject(error);
    }
);

export default axioInstance;
import React, { useState, useEffect } from "react";
import axioInstance from "../components/axiosInstance";
import { useNavigate } from "react-router-dom";
import NewTheme from "../components/admin-dashboard/NewTheme";
import TableContainer from "../components/admin-dashboard/TableContainer";

export default function PlanLoginLogoutLogsNew() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state

  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("admin_id");
    //console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {
      axioInstance
        .post(`admin/get-login-logout-logs`)
        .then((response, data) => {
          setData(response.data.rows);
          setTableRowsData(response.data.rows);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Turn off the loader
        });
    }
  }, [navigate]);
 
 
useEffect(() => {
  setTimeout(()=>{
    // Ensure the DOM is ready and `applyDataTable` is available globally
    if (typeof window.applyDataTable === "function") {

        window.applyDataTable(
          '#datatable_1', 
          {
            "bFilter": true,
            "order": [ [1, 'desc'] ]
          } /* , 
          function(datatable) {
            
            // Add custom filtering logic
              const adminFilterInput = document.getElementById('admin_filter_name');
              if (adminFilterInput) {
                  adminFilterInput.addEventListener('keyup', function() {
                    datatable.column(2).search(this.value).draw();
                  });
              }

          }
  */
        );
    }
},5000);
}, [tableRowsData]); 


  return (
    <NewTheme loading={loading}>
      <div className="page-header">
        <div className="content-page-header">
          <h5>PLAN Login Logout Logs Dashboard</h5>
          <div className="list-btn">
            {/* 
              <ul className="filter-list">
                <li>
                    <Link className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                        <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                    </Link>
                </li>  
              </ul> 
            */}
          </div>
        </div>
      </div>

      <TableContainer>
        <table className="table table-stripped table-hover " id="datatable_1">
          <thead className="thead-light">
            <tr>
              <th>ID</th>
              <th>Client Name</th>
              <th>Admin Name</th>
              <th>Event Value</th>
              <th>User Type</th>
              <th>IP</th>
              <th>Location</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {tableRowsData.map((row, index) => {
              return <tr key={index}>
                <td>{row.log_id}</td>
                <td>{row.full_name}</td>
                <td>{row.admin_name}</td>
                <td>{row.event_value}</td>
                <td>{row.user_type}</td>
                <td>{row.ip_address}</td>
                <td>{row.ip_address}</td>
                <td>{row.created}</td>
              </tr>;
            })}
          </tbody>
        </table>
      </TableContainer>
    </NewTheme>
  );
}

import React, { useState, useEffect } from 'react'
import WelcomeNote from '../components/fpreports/WelcomeNote'
import Disclaimer from '../components/fpreports/Disclaimer'
import PersonalInformation from '../components/fpreports/PersonalInformation'
import CashflowAnalysis from '../components/fpreports/CashflowAnalysis'
import Assetswhatyouown from '../components/fpreports/Assetswhatyouown'
import NetworthAnalysis from '../components/fpreports/NetworthAnalysis'
import EmergencyFund from '../components/fpreports/EmergencyFund'
import LoanManagement from '../components/fpreports/LoanManagement'
import LifeInsuranceAnalysis from '../components/fpreports/LifeInsuranceAnalysis'
import HealthAnalysis from '../components/fpreports/HealthAnalysis'
import GoalSummary from '../components/fpreports/GoalSummary'
import DashboardHeader from '../components/layout/DashboardHeader'
import RiskProfileSummary from '../components/fpreports/RiskProfileSummary'
import axioInstance from '../components/axiosInstance'
import FinnLoader from '../components/FinnLoader'
import FinnModal from '../components/FinnModal'
import { useNavigate } from "react-router-dom";

export default function FpReport() {

    const [active, setActive] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [summaryData, setSummaryData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [profiletermData, setProfiletermData] = useState([]);
    const [groupInvestmentData, setGroupInvestmentData] = useState([]);
    const [recommendedAssetsData, setRecommendedAssetsData] = useState([]);
    const [summaryCashflowData, setSummaryCashflowData] = useState([]);
    const [lifeCycleData, setLifeCycleData] = useState([]);
    const [planStatusHistoryData, setPlanStatusHistoryData] = useState([]);
    const [riskProfileData, setRiskProfileData] = useState([]);
    const [finnfitData, setFinnfitData] = useState([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    // eslint-disable-next-line
    const [errorMessage, setErrorMessage] = useState("");
    const [errorTitle, setErrorTitle] = useState("Something went wrong");
    const [errorFooter, setErrorFooter] = useState();
    const [showButton, setShowButton] = useState(true);


    const [countdown, setCountdown] = useState(3); // Start countdown from 3

    useEffect(() => {
        document.body.classList.add('white_bg_fp_report');

        return function cleanup() {
            document.body.classList.remove('white_bg_fp_report');
        };
    }, []);

    const navigate = useNavigate();

    useEffect(() => {

        const profile_id = sessionStorage.getItem("profile_id");
        if (profile_id) {

            axioInstance
                .post("profile/plan-fpreport", { profile_id })
                .then((response) => {

                    if (response.data.status === 100) {
                        // console.log("line 66>>>",response.data.termData);
                        setSummaryData(response?.data?.summaryData || []);
                        setFamilyData(response?.data?.familyData || []);
                        setProfiletermData(response?.data?.termData || []);
                        setGroupInvestmentData(response?.data?.groupInvestmentData || []);
                        setRecommendedAssetsData(response?.data?.recommendedAssetsData || []);
                        setSummaryCashflowData(response?.data?.summaryCashflowData || []);
                        setLifeCycleData(response?.data?.lifeCycleData || []);
                        setPlanStatusHistoryData(response?.data?.planStatusHistoryData || []);
                        setRiskProfileData(response?.data?.riskProfileData || []);
                        setFinnfitData(response?.data?.finnfitData?.data || []);
                    }

                })
                .catch((error) => {

                    if (error.message === "Token has expired" || error.message === "Request failed with status code 401") {

                        setErrorTitle("Session Expired!");
                        setErrorMessage(
                            <div>
                                <strong className='text-danger'>Your session has expired. Please log in again.</strong>
                                <br /><small>Redirecting to login page in <span className="countdown">{countdown}</span> sec...</small>
                            </div>
                        );
                        setErrorFooter('');
                        setShowButton(false);
                        setShowErrorModal(true);

                        // Start countdown
                        let interval = setInterval(() => {
                            setCountdown((prev) => {
                                if (prev === 1) {
                                    clearInterval(interval);

                                    sessionStorage.removeItem("token");
                                    sessionStorage.removeItem("profile_id");
                                    sessionStorage.removeItem("full_name");
                                    navigate(sessionStorage.getItem("admin_id") ? "/ulogin" : "/sign-in"); // Redirect to login
                                    sessionStorage.clear();

                                }
                                return prev - 1;
                            });
                        }, 1000);

                    } else {
                        setErrorMessage(
                            <div>
                                <p><strong className='text-danger font18'>Failed to fetch data.</strong></p>
                                <p>Please check your internet connection and try again.</p>
                                <ul>
                                    <li>Refresh the page Or Try again later</li>
                                </ul>
                            </div>
                        );
                        setErrorFooter(<div>
                            <p>For assistance, <a href="mailto:support@finnovate.in">Contact Us</a></p>
                        </div>);
                        setShowButton(true);
                        setShowErrorModal(true);
                    }

                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [countdown, navigate]);

    return (
        <>

            <DashboardHeader />
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    {loading && <FinnLoader />}
                    <div className="planSection_wpr row">
                        <div className="col-3 leftNav pt55 font14 fw500 color7B8">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                <a className="nav-link active" id="Welcomenote-tab" data-toggle="pill" href="#Welcomenote" role="tab" aria-controls="Welcomenote" aria-selected="true" onClick={() => setActive(1)}> <span className="welcomeNoteIcon mr10" /> Welcome note</a>

                                <a className="nav-link" id="Disclaimer-tab" data-toggle="pill" href="#Disclaimer" role="tab" aria-controls="Disclaimer" aria-selected="false" onClick={() => setActive(2)}><span className="disclaimerIcon mr10" /> Disclaimer</a>

                                <a className="nav-link" id="PersonalInformation-tab" data-toggle="pill" href="#PersonalInformation" role="tab" aria-controls="PersonalInformation" aria-selected="false" onClick={() => setActive(3)}><span className="personalInformationIcon mr10" /> Personal Information</a>

                                <a className="nav-link" id="CashflowAnalysis-tab" data-toggle="pill" href="#CashflowAnalysis" role="tab" aria-controls="CashflowAnalysis" aria-selected="false" onClick={() => setActive(4)}><span className="cashflowAnalysisIcon mr10" /> Cashflow Analysis</a>

                                <a className="nav-link" id="Whatyouown-tab" data-toggle="pill" href="#Whatyouown" role="tab" aria-controls="Whatyouown" aria-selected="false" onClick={() => setActive(5)}><span className="assetsIcon mr10" /> Assets - What you own</a>

                                <a className="nav-link" id="NetworthAnalysis-tab" data-toggle="pill" href="#NetworthAnalysis" role="tab" aria-controls="NetworthAnalysis" aria-selected="false" onClick={() => setActive(6)}><span className="networthAnalysisIcon mr10" /> Networth Analysis</a>

                                <a className="nav-link" id="EmergencyFund-tab" data-toggle="pill" href="#EmergencyFund" role="tab" aria-controls="EmergencyFund" aria-selected="false" onClick={() => setActive(7)}><span className="emergencyFundIcon mr10" /> Emergency Fund</a>

                                <a className="nav-link" id="LoanManagement-tab" data-toggle="pill" href="#LoanManagement" role="tab" aria-controls="LoanManagement" aria-selected="false" onClick={() => setActive(8)}><span className="loanManagementIcon mr10" /> Loan Management</a>

                                <a className="nav-link" id="LifeInsuranceAnalysis-tab" data-toggle="pill" href="#LifeInsuranceAnalysis" role="tab" aria-controls="LifeInsuranceAnalysis" aria-selected="false" onClick={() => setActive(9)}><span className="lifeAnalysisIcon mr10" /> Life Insurance Analysis</a>

                                <a className="nav-link" id="HealthAnalysis-tab" data-toggle="pill" href="#HealthAnalysis" role="tab" aria-controls="HealthAnalysis" aria-selected="false" onClick={() => setActive(10)}><span className="healthAnalysisIcon mr10" /> Health Analysis</a>

                                <a className="nav-link" id="GoalSummary-tab" data-toggle="pill" href="#GoalSummary" role="tab" aria-controls="GoalSummary" aria-selected="false" onClick={() => setActive(11)}><span className="goalSummaryIcon mr10" /> Goal Summary</a>

                                <a className="nav-link" id="RiskProfileSummary-tab" data-toggle="pill" href="#RiskProfileSummary" role="tab" aria-controls="RiskProfileSummary" aria-selected="false" onClick={() => setActive(12)}><span className="goalSummaryIcon mr10" /> Plan History</a>
                            </div>

                        </div>
                        <div className="col-9 righttabContent pl90 pt25">
                            <div className="tab-content" id="v-pills-tabContent">
                                {
                                    active === 1 && (<><div className="tab-pane fade show active" id="Welcomenote" role="tabpanel" aria-labelledby="Welcomenote-tab">
                                        <WelcomeNote summaryData={summaryData} error={error} />
                                    </div></>)
                                }

                                {
                                    active === 2 && (<><div className="tab-pane fade" id="Disclaimer" role="tabpanel" aria-labelledby="Disclaimer-tab">
                                        <Disclaimer />
                                    </div></>)
                                }


                                {
                                    active === 3 && (<><div className="tab-pane fade" id="PersonalInformation" role="tabpanel" aria-labelledby="PersonalInformation-tab">
                                        <PersonalInformation familyData={familyData} error={error} setError={setError} />
                                    </div></>)
                                }


                                {
                                    active === 4 && (<><div className="tab-pane fade" id="CashflowAnalysis" role="tabpanel" aria-labelledby="CashflowAnalysis-tab">
                                        <CashflowAnalysis summaryData={summaryData} profiletermData={profiletermData} error={error} />
                                    </div></>)
                                }


                                {
                                    active === 5 && (<><div className="tab-pane fade" id="Whatyouown" role="tabpanel" aria-labelledby="Whatyouown-tab">
                                        <Assetswhatyouown summaryData={summaryData} groupInvestmentData={groupInvestmentData} error={error} setError={setError} />
                                    </div></>)
                                }


                                {
                                    active === 6 && (<><div className="tab-pane fade" id="NetworthAnalysis" role="tabpanel" aria-labelledby="NetworthAnalysis-tab">
                                        <NetworthAnalysis summaryData={summaryData} groupInvestmentData={groupInvestmentData} error={error} setError={setError} />
                                    </div></>)
                                }


                                {
                                    active === 7 && (<><div className="tab-pane fade" id="EmergencyFund" role="tabpanel" aria-labelledby="EmergencyFund-tab">
                                        <EmergencyFund recommendedAssetsData={recommendedAssetsData} error={error} />
                                    </div></>)
                                }


                                {
                                    active === 8 && (<><div className="tab-pane fade" id="LoanManagement" role="tabpanel" aria-labelledby="LoanManagement-tab">
                                        <LoanManagement summaryData={summaryData} error={error} />
                                    </div></>)
                                }


                                {
                                    active === 9 && (<><div className="tab-pane fade" id="LifeInsuranceAnalysis" role="tabpanel" aria-labelledby="LifeInsuranceAnalysis-tab">
                                        <LifeInsuranceAnalysis summaryData={summaryData} error={error} />
                                    </div></>)
                                }


                                {
                                    active === 10 && (<><div className="tab-pane fade" id="HealthAnalysis" role="tabpanel" aria-labelledby="HealthAnalysis-tab">
                                        <HealthAnalysis summaryData={summaryData} error={error} />
                                    </div></>)
                                }


                                {
                                    active === 11 && (<><div className="tab-pane fade" id="GoalSummary" role="tabpanel" aria-labelledby="GoalSummary-tab">
                                        <GoalSummary summaryData={summaryData} profiletermData={profiletermData} summaryCashflowData={summaryCashflowData} error={error} setError={setError} />
                                    </div></>)
                                }

                                {
                                    active === 12 && (<><div className="tab-pane fade" id="RiskProfileSummary" role="tabpanel" aria-labelledby="RiskProfileSummary-tab">
                                        <RiskProfileSummary summaryData={summaryData} lifeCycleData={lifeCycleData} planStatusHistoryData={planStatusHistoryData} riskProfileData={riskProfileData} error={error} setError={setError} finnfitData={finnfitData}/>
                                    </div></>)
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <FinnModal
                show={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                title={errorTitle}
                message={errorMessage}
                footer={errorFooter}
                isClosable={false} // Set to false if you don't want it to be closable
                isAction={showButton}
                primaryButtonText="Refresh"
                secondaryButtonText="Back to Dashboard"
                onPrimaryClick={() => {
                    setShowErrorModal(false);
                    window.location.reload();
                }}
                onSecondaryClick={() => window.location.href = 'dashboard'}
            />
        </>
    )
}
